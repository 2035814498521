import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket, faAward, faCircleUser, faDoorClosed, faFolderUser, faGear, faKey, faMessageSmile, faMoon } from '@fortawesome/pro-regular-svg-icons';
import { AvatarDropdownCategoriesProps } from '@tryhackme/thm-ui-components/dist/components/compounds/header/components/avatar-dropdown/avatar-dropdown.types';
import { AdminLink } from '@tryhackme/thm-ui-components';
import { UserAccountTypes, UserRoles } from 'src/common/enums';
import { AdminLinkWithId } from '../header.types';
import { adminLinks, linksByRole } from './admin-links';

// Keep in mind to replace dynamic content that is in brackets e.g. [username]
// If you are changing this, change this property in component library to 3, because we are using indexes there
// ^ Above comment might be outdated but not deleting it just in case
export const avatarDropdownItems = (username: string | undefined, urgencyIndicator: boolean, isDarkModeEnabled: boolean, isMyLearningEnabled: boolean): AvatarDropdownCategoriesProps => {
  const allItems = [{
    items: [{
      name: 'View Profile',
      href: `/p/${username ?? ''}`,
      icon: <FontAwesomeIcon icon={faCircleUser} />
    }, {
      name: 'Manage Account',
      href: '/manage-account',
      icon: <FontAwesomeIcon icon={faGear} />,
      urgencyIndicator
    }]
  }, {
    items: [{
      name: 'Badges',
      href: '/badges',
      icon: <FontAwesomeIcon icon={faAward} />
    }, {
      name: isMyLearningEnabled ? 'My Learning' : 'My Rooms',
      href: isMyLearningEnabled ? '/learning' : '/rooms',
      icon: <FontAwesomeIcon icon={isMyLearningEnabled ? faFolderUser : faDoorClosed} />
    }, {
      name: 'Access',
      href: '/access',
      icon: <FontAwesomeIcon icon={faKey} />
    }, {
      name: 'Give Feedback',
      href: '/feedback',
      icon: <FontAwesomeIcon icon={faMessageSmile} />
    }]
  }, {
    items: [{
      name: 'Log Out',
      href: '/logout',
      icon: <FontAwesomeIcon icon={faArrowRightToBracket} />
    }]
  }];
  const darkModeObject = {
    name: 'Dark Mode',
    icon: <FontAwesomeIcon icon={faMoon} />,
    href: ''
  };
  if (true) {
    allItems[0]?.items.push(darkModeObject);
  }
  return allItems;
};
const sortLinksById = (a: AdminLinkWithId, b: AdminLinkWithId) => a.id - b.id;
export const getAdminLinks = ({
  roles,
  accountType
}: {
  roles?: UserRoles[];
  accountType?: UserAccountTypes;
} = {}) => {
  const links: (AdminLink & {
    id: number;
  })[] = [];

  // If user is an admin, return all links except submissions
  if (accountType === UserAccountTypes.ADMIN) {
    links.push(...adminLinks);
  }
  if (!roles?.length) return (links.sort(sortLinksById) as AdminLink[]);

  // If user is not an admin or has is an admin but has a role with a link that admins cannot see, add links based on the roles
  for (const role of roles) {
    const currentLinks = linksByRole[role];
    // If there are links for the current role
    if (currentLinks) {
      for (const currentLink of currentLinks) {
        if (!links.some(link => currentLink.title === link.title)) links.push(currentLink);
      }
    }
  }
  return (links.sort(sortLinksById) as AdminLink[]);
};