import { Button, StyledFlex } from '@tryhackme/thm-ui-components';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SocSimLogo } from 'src/images/svgs/soc-sim/soc-sim-logo.svg';
import { StyledContainer, StyledText, StyledTitleContainer } from './soc-sim-ad-banner.styles';
export const SocSimAdBanner = () => {
  const navigate = useNavigate();
  return <StyledContainer data-sentry-element="StyledContainer" data-sentry-component="SocSimAdBanner" data-sentry-source-file="soc-sim-ad-banner.tsx">
      <StyledFlex flexDirection="column" gap="3rem" alignItems="flex-start" data-sentry-element="StyledFlex" data-sentry-source-file="soc-sim-ad-banner.tsx">
        <StyledFlex flexDirection="column" gap="2rem" alignItems="flex-start" data-sentry-element="StyledFlex" data-sentry-source-file="soc-sim-ad-banner.tsx">
          <StyledTitleContainer data-sentry-element="StyledTitleContainer" data-sentry-source-file="soc-sim-ad-banner.tsx">
            <span>NEW</span>
            <h2>Master real-world cyber threats with hands-on training on the SOC Simulator</h2>
          </StyledTitleContainer>
          <StyledText data-sentry-element="StyledText" data-sentry-source-file="soc-sim-ad-banner.tsx">
            Develop the curious mindset of an analyst. Engage in realistic scenarios and hands-on training designed to
            prepare you for real-world cyber security challenges.
          </StyledText>
        </StyledFlex>
        <Button onClick={() => {
        navigate('/soc-sim');
      }} color="primary" variant="solid" data-sentry-element="Button" data-sentry-source-file="soc-sim-ad-banner.tsx">
          Launch simulator
        </Button>
      </StyledFlex>
      <SocSimLogo data-sentry-element="SocSimLogo" data-sentry-source-file="soc-sim-ad-banner.tsx" />
    </StyledContainer>;
};