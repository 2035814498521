import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export type TutorMessageVote = 1 | -1;

export enum TutorMessageSender {
  USER = 'user',
  SYSTEM = 'system',
  LLM = 'llm',
  NUDGE = 'nudge',
}

export interface TutorMessage {
  id?: string;
  message: string;
  sender: TutorMessageSender;
  suggestions?: string[];
  type: 'button' | 'free-text';
  vote?: TutorMessageVote;
  timestamp?: Date;
}

export interface ReadTutorMessages {
  status: 'active' | 'inactive' | 'disabled';
  tutorSessionId: string;
  messages: TutorMessage[];
}

export type SendTutorMessage = Omit<TutorMessage, 'id' | 'timestamp' | 'suggestions' | 'vote'>;

export interface SendTutorMessageVote {
  tutorSessionId: string;
  messageId: string;
  vote: TutorMessageVote;
}

export enum TutorSocketEventType {
  READ_MESSAGES = 'user:read_messages',
  SEND_MESSAGE = 'user:send_message',
  CLEAR_MESSAGES = 'user:clear_messages',
  VOTE_MESSAGE = 'user:vote_message',
  RECEIVE_MESSAGE = 'tutor:send_message',
  RECEIVE_NUDGE = 'tutor:send-nudge',
  INAVLIDATE_BUTTONS = 'tutor:invalidate_buttons',
  CLOSE_AI_TERMS_OF_USE_BANNER = 'user:close_ai_terms_of_use_banner',
  TOGGLE_NOTIFICATIONS = 'user:toggle_notifications',
  UPDATE_DISPLAY_MODE = 'user:update_display_mode',
  ERROR = 'tutor:error',
}

export enum TutorSocketEventErrorType {
  RATE_LIMITED = 'rate_limited',
  CONNECTION_ERROR = 'connection_error',
  INVALID_INPUT = 'invalid_input',
  SESSION_EXPIRED = 'session_expired',
  MESSAGE_TOO_LONG = 'message_too_long',
  LIGHT_ERROR = 'light_error',
}

export interface TutorErrorResponse {
  message: string;
  type: string;
  retryAfter?: number;
  retryAfterMessage?: string;
  originalPayload?: SendTutorMessage;
}

export enum TutorErrorMessages {
  RATE_LIMITED = "Oops! You're sending messages too quickly. Please try again in a little while.",
  CONNECTION_ERROR = 'Connection lost. Please check your internet and try again.',
  INVALID_INPUT = 'Invalid input. Please check your message and try again.',
  SESSION_EXPIRED = 'Your session has expired. Please refresh the chat.',
  MESSAGE_TOO_LONG = 'Your message is too long. Please limit it to 450 characters.',
  DEFAULT = 'Oops! Something went wrong. Please try restarting the chat.',
  LIGHT_MESSAGE = "We've hit a temporary cap—don't worry, everything will be back soon. Thanks for your patience!",
}

export enum TutorErrorActionTexts {
  RETRY = 'Retry',
  RECONNECT = 'Reconnect',
  OK = 'OK',
  REFRESH_CHAT = 'Refresh Chat',
}

export interface TutorIntroMessageButton {
  icon: IconDefinition;
  text: string;
  isPrimary: boolean;
  isVisible: boolean;
  key: 'stuck-button' | 'learn-more-button';
  onClick: () => void;
}

export interface TutorIntroMessage {
  header: string;
  body: {
    options: {
      icon: IconDefinition;
      text: string;
      key: string;
    }[];
  };
  footer: {
    text: string;
    buttons: TutorIntroMessageButton[];
    isVisible?: boolean;
  };
  isVisible?: boolean;
  onOptionClick?: (key: string) => void;
  onButtonClick?: (key: string) => void;
}

export type TutorDraggingState = 'drag-start' | 'dragging' | 'drag-stop' | 'idle';

export enum TutorDisplayMode {
  FLOATING = 'floating',
  EMBEDDED = 'embedded',
}
