import { useCallback, useEffect, useState } from 'react';

export function useMediaQuery(query: string): boolean {
  const initialState = window.matchMedia(query).matches;

  const [matches, setMatches] = useState<boolean>(initialState);

  const handleChange = useCallback(() => {
    setMatches(window.matchMedia(query).matches);
  }, [query]);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen to matchMedia
    if (matchMedia.addListener) matchMedia.addListener(handleChange);
    else matchMedia.addEventListener('change', handleChange);

    return () => {
      if (matchMedia.removeListener) matchMedia.removeListener(handleChange);
      else matchMedia.removeEventListener('change', handleChange);
    };
  }, [handleChange, query]);

  return matches;
}
