import { configureStore } from '@reduxjs/toolkit';

import { apiSlice } from 'src/features/api/api-slice';
import { ghostContentApiSlice } from 'src/features/api/ghost-slice';
import { roomReducer } from 'src/app/reducers/room/room.reducer';

import { splitScreenReducer } from './reducers/split-screen.reducer';
import { manageRoomReducer } from './reducers/manage-room.reducer';
import { modalStatesReducer } from './reducers/modal-states.reducer';
import { certificationReducer } from './reducers/certification/certification.reducer';
import { socSimReducer } from './reducers/soc-sim.reducer';
import { tutorReducer } from './reducers/room/tutor.reducer';

export const store = configureStore({
  reducer: {
    manageRoom: manageRoomReducer,
    modalStates: modalStatesReducer,
    splitScreen: splitScreenReducer,
    socSim: socSimReducer,
    certification: certificationReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [ghostContentApiSlice.reducerPath]: ghostContentApiSlice.reducer,
    room: roomReducer,
    tutor: tutorReducer,
  },
  middleware: (getDefaultMiddleware) =>
    // Disable serializableCheck as we currently have no reason to keep store date serializable
    // eslint-disable-next-line unicorn/prefer-spread -- this results in different and more precise TS behavior 😵‍💫
    getDefaultMiddleware({ serializableCheck: false }).concat([apiSlice.middleware, ghostContentApiSlice.middleware]),
});

export type Store = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
