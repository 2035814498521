import type { SocSimBannerProps } from './soc-sim-banner.types';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Button, darkColors } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { StyledBannerContentWrapper, StyledBannerImage, StyledBannerNeonLight, StyledBannerTextWrapper, StyledCTAWrapper, StyledImageFrame, StyledSocSimBannerCloseButton, StyledSocSimBannerContainer, StyledSocSimBannerWrapper, StyledHeadingWrapper } from './soc-sim-banner.styles';
export const SocSimBanner = ({
  dismiss,
  isUserLoggedIn
}: SocSimBannerProps) => {
  const theme = useTheme();
  return <ThemeProvider theme={{
    ...theme,
    colors: darkColors
  }} data-sentry-element="ThemeProvider" data-sentry-component="SocSimBanner" data-sentry-source-file="soc-sim-banner.tsx">
      <StyledSocSimBannerContainer data-sentry-element="StyledSocSimBannerContainer" data-sentry-source-file="soc-sim-banner.tsx">
        <StyledSocSimBannerWrapper data-sentry-element="StyledSocSimBannerWrapper" data-sentry-source-file="soc-sim-banner.tsx">
          <StyledBannerContentWrapper data-sentry-element="StyledBannerContentWrapper" data-sentry-source-file="soc-sim-banner.tsx">
            <div>
              <StyledBannerTextWrapper data-sentry-element="StyledBannerTextWrapper" data-sentry-source-file="soc-sim-banner.tsx">
                <StyledHeadingWrapper data-sentry-element="StyledHeadingWrapper" data-sentry-source-file="soc-sim-banner.tsx">
                  <span>NEW IN</span>
                  <h1>Build real-world expertise in a simulated SOC environment</h1>
                </StyledHeadingWrapper>
                <p>
                  Tackle authentic challenges, and improve performance through actionable feedback{' '}
                  <span>powered by AI</span> tailored to enhance your readiness.
                </p>
              </StyledBannerTextWrapper>
              <StyledCTAWrapper data-sentry-element="StyledCTAWrapper" data-sentry-source-file="soc-sim-banner.tsx">
                <Link to="/soc-sim" data-sentry-element="Link" data-sentry-source-file="soc-sim-banner.tsx">
                  <Button color="primary" variant="solid" onClick={() => ''} data-sentry-element="Button" data-sentry-source-file="soc-sim-banner.tsx">
                    Launch simulator
                  </Button>
                </Link>
                <Link to="/resources/blog/soc-simulator-launch" data-sentry-element="Link" data-sentry-source-file="soc-sim-banner.tsx">
                  <Button color="primary" variant="outlined" onClick={() => ''} data-sentry-element="Button" data-sentry-source-file="soc-sim-banner.tsx">
                    Learn more
                  </Button>
                </Link>
              </StyledCTAWrapper>
            </div>
            <StyledBannerNeonLight data-sentry-element="StyledBannerNeonLight" data-sentry-source-file="soc-sim-banner.tsx">
              <StyledImageFrame data-sentry-element="StyledImageFrame" data-sentry-source-file="soc-sim-banner.tsx">
                <StyledBannerImage data-sentry-element="StyledBannerImage" data-sentry-source-file="soc-sim-banner.tsx" />
              </StyledImageFrame>
            </StyledBannerNeonLight>
          </StyledBannerContentWrapper>
          <div>
            {isUserLoggedIn && <StyledSocSimBannerCloseButton aria-label="close-notification" icon={faXmark} onClick={dismiss} />}
          </div>
        </StyledSocSimBannerWrapper>
      </StyledSocSimBannerContainer>
    </ThemeProvider>;
};