import { useContext } from 'react';
import { SwitchThemeContext } from '@tryhackme/thm-ui-components';
import SocSimLogoIcon from 'src/images/svgs/soc-sim/soc-sim-icon.svg';
import { ThemeColorMode } from 'src/common/enums';
import { useMediaQuery } from 'src/common/hooks/use-media-query';
import { BREAKPOINTS } from 'src/common/constants/breakpoints';
import { StyledSocSimIconLogo } from './soc-sim-icon-logo.styles';
export const SocSimIconLogo: React.FC = () => {
  const {
    theme
  } = useContext(SwitchThemeContext);
  const isDarkModeTheme = theme === ThemeColorMode.DARK;
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.MOBILE}px)`);
  const isDarkMode = !!window.location.pathname.includes('/r/soc-sim') || isMobile || isDarkModeTheme;
  return <StyledSocSimIconLogo isDark={isDarkMode} src={SocSimLogoIcon} alt="soc simulator logo" data-sentry-element="StyledSocSimIconLogo" data-sentry-component="SocSimIconLogo" data-sentry-source-file="soc-sim-icon-logo.tsx" />;
};