import { useCallback, useEffect } from 'react';
import { ChatWindow, ChatMessage, ChatPersona } from '@tryhackme/thm-ui-components';
import { useDispatch } from 'react-redux';
import { ChatWindowProps } from '@tryhackme/thm-ui-components/dist/components/compounds/tutor/chat-window.types';
import { faArrowLeftToLine, faArrowsFromDottedLine, faArrowDownLeftAndArrowUpRightToCenter, faArrowUpRightAndArrowDownLeftFromCenter, faBell, faBellSlash, faChevronDown, faEllipsis, faRedo, faThoughtBubble, faArrowsToDottedLine } from '@fortawesome/pro-regular-svg-icons';
import Echo from 'src/images/pngs/landing/tutor/echo.png';
import { SendTutorMessage, SendTutorMessageVote, TutorDisplayMode, TutorMessageSender } from 'src/features/room/components/tutor/tutor.types';
import { AI_TERMS_OF_USE_URL, AI_TUTOR_NAME, DEFAULT_TUTOR_LANDING_PAGE_URL, FeatureFlagName, TUTOR_STUCK_MESSAGE } from 'src/common/constants';
import { useAppSelector } from 'src/app/hooks';
import { useGetFeatureFlagsQuery, useGetUserQuery } from 'src/common/slices';
import { setTutorPanelOpen } from 'src/app/reducers/split-screen.reducer';
import { useGetRoomDetailQuery } from 'src/features/room/room.slice';
import * as TutorReducer from 'src/app/reducers/room/tutor.reducer';
import { useTutorSession, useTutorMessages, useTutorSocket, useTutorTracking, useIntroMessage } from './hooks';
import { getTutorIntroMessage } from './tutor.helper';
export const TutorWindow = ({
  persona = {
    name: AI_TUTOR_NAME,
    imgUrl: Echo
  },
  roomCode
}: {
  persona?: ChatPersona;
  roomCode: string;
}) => {
  const {
    data: featureFlagsQueryData
  } = useGetFeatureFlagsQuery();
  const isNotificationsToggleEnabled = featureFlagsQueryData?.data?.some(flag => flag.name === FeatureFlagName.TUTOR_NOTIFICATIONS_TOGGLE && flag.enabled) ?? false;
  const {
    data: roomData
  } = useGetRoomDetailQuery(roomCode);
  const {
    data: userData
  } = useGetUserQuery();
  const roomDetails = roomData?.data;
  const roomDifficulty = roomDetails?.difficulty;
  const roomId = roomDetails?._id;
  const roomType = roomDetails?.type;
  const userId = userData?.data?.user?._id;
  const {
    chat: {
      open: isTutorOpen,
      loading: isLoading,
      restarting: isRestarting,
      disabled: isChatDisabled,
      width: chatWidth,
      displayMode,
      draggingState,
      notificationsEnabled,
      hasSeenAiTermsOfUseBanner
    }
  } = useAppSelector(state => state.tutor);
  const dispatch = useDispatch();
  const {
    tutorSessionId,
    setTutorSessionId
  } = useTutorSession(roomCode);
  const {
    messages,
    updateMessagesWithDelay,
    clearMessages,
    addUserMessage,
    addUserVote
  } = useTutorMessages();
  const {
    sendMessage,
    readMessages,
    clearMessages: clearSocketMessages,
    voteMessage,
    closeTermsOfUseBanner,
    toggleNotifications,
    activeChatError,
    isErrorActive,
    updateDisplayMode
  } = useTutorSocket({
    roomId,
    userId,
    tutorSessionId,
    setMessages: updateMessagesWithDelay,
    setIsLoading: state => dispatch(TutorReducer.setIsLoading(state)),
    setIsRestarting: state => dispatch(TutorReducer.setIsRestarting(state)),
    setTutorSessionId,
    setIsChatDisabled: state => dispatch(TutorReducer.setIsChatDisabled(state))
  });
  const {
    trackTutorClose,
    trackMessageSent,
    trackVote,
    trackChatRestart,
    trackIntroMessageButtonClicked,
    trackSendFeedbackClicked,
    trackTermsOfUseBannerClosed,
    trackTutorNotificationsToggle
  } = useTutorTracking(roomCode, roomType, roomDifficulty, tutorSessionId);
  const fullName = userData?.data?.user?.fullName;
  const username = userData?.data?.user?.username;
  const userName = fullName ? fullName.split(' ')[0] : username;
  const {
    introMessage,
    updateButton: updateIntroMessageButton
  } = useIntroMessage({
    defaultConfig: getTutorIntroMessage({
      username: userName ?? ''
    }),
    roomCode,
    tutorSessionId,
    messagesLength: messages.length,
    onStuckButtonClick: () => {
      const newMessage: SendTutorMessage = {
        message: TUTOR_STUCK_MESSAGE,
        sender: TutorMessageSender.USER,
        type: 'button'
      };
      addUserMessage(newMessage.message, newMessage.type);
      sendMessage(newMessage);
      trackIntroMessageButtonClicked('stuck-button');
    },
    onLearnMoreClick: () => {
      window.open(DEFAULT_TUTOR_LANDING_PAGE_URL, '_blank');
      trackIntroMessageButtonClicked('learn-more-button');
    }
  });
  const isInputDisabled = isErrorActive || isLoading || isRestarting || isChatDisabled;
  if (messages.length > 0) {
    if (messages.length >= 2) {
      const button = introMessage.footer.buttons.find(btn => btn.key === 'stuck-button');
      if (button?.isVisible) {
        updateIntroMessageButton('stuck-button', {
          isVisible: false
        });
      }
    } else {
      const button = introMessage.footer.buttons.find(btn => btn.key === 'stuck-button');
      if (!button?.isVisible) {
        updateIntroMessageButton('stuck-button', {
          isVisible: true
        });
      }
    }
  }
  useEffect(() => {
    if (isTutorOpen && !isRestarting && !messages.length) {
      dispatch(TutorReducer.setIsLoading(true));
      readMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTutorOpen]);
  const handleSendMessage = useCallback((message: string) => {
    const type = 'free-text';
    const newMessage: SendTutorMessage = {
      message,
      sender: TutorMessageSender.USER,
      type
    };
    addUserMessage(message, type);
    sendMessage(newMessage);
    trackMessageSent(newMessage);
  }, [addUserMessage, sendMessage, trackMessageSent]);
  const handleSuggestionClick = useCallback((message: string) => {
    const type = 'button';
    const newMessage: SendTutorMessage = {
      message,
      sender: TutorMessageSender.USER,
      type
    };
    addUserMessage(message, type);
    sendMessage(newMessage);
    trackMessageSent(newMessage);
  }, [addUserMessage, sendMessage, trackMessageSent]);
  const handleVoteMessage = useCallback((vote: 1 | -1, messageId: string) => {
    if (!tutorSessionId) return;
    const voteMessagePayload: SendTutorMessageVote = {
      tutorSessionId,
      vote,
      messageId
    };
    addUserVote(voteMessagePayload.vote, messageId);
    voteMessage(voteMessagePayload);
    trackVote(voteMessagePayload);
  }, [voteMessage, trackVote, addUserVote, tutorSessionId]);
  const handleCloseTutor = useCallback(() => {
    dispatch(setTutorPanelOpen(false));
    dispatch(TutorReducer.closeTutor());
    trackTutorClose();
  }, [dispatch, trackTutorClose]);
  const handleChatRestart = useCallback(() => {
    clearMessages();
    clearSocketMessages();
    trackChatRestart();
  }, [clearMessages, clearSocketMessages, trackChatRestart]);
  const handleSendFeedback = useCallback(() => {
    window.open('https://forms.gle/M8dxg2ek5Z1sBhJd8', '_blank', 'noopener');
    trackSendFeedbackClicked();
  }, [trackSendFeedbackClicked]);
  const handleTermsOfUseBannerClose = useCallback(() => {
    closeTermsOfUseBanner();
    trackTermsOfUseBannerClosed();
  }, [closeTermsOfUseBanner, trackTermsOfUseBannerClosed]);
  const handleHeaderHover = (state: 'enter' | 'leave') => {
    if (displayMode === TutorDisplayMode.EMBEDDED || draggingState === 'dragging') return;
    if (state === 'enter') dispatch(TutorReducer.setDraggingState('drag-start'));
    if (state === 'leave') dispatch(TutorReducer.setDraggingState('idle'));
  };
  const handleNotificationsToggle = useCallback(() => {
    if (!isNotificationsToggleEnabled) return;
    const newNotificationsEnabled = !notificationsEnabled;
    dispatch(TutorReducer.setNotificationsEnabled(newNotificationsEnabled));
    toggleNotifications(newNotificationsEnabled);
    trackTutorNotificationsToggle(newNotificationsEnabled);
  }, [isNotificationsToggleEnabled, notificationsEnabled, dispatch, toggleNotifications, trackTutorNotificationsToggle]);
  const handleDisplayModeToggle = useCallback(() => {
    if (displayMode === TutorDisplayMode.FLOATING) {
      dispatch(setTutorPanelOpen(true));
      dispatch(TutorReducer.setDisplayMode(TutorDisplayMode.EMBEDDED));
      updateDisplayMode(TutorDisplayMode.EMBEDDED);
    } else {
      dispatch(setTutorPanelOpen(false));
      dispatch(TutorReducer.setDisplayMode(TutorDisplayMode.FLOATING));
      updateDisplayMode(TutorDisplayMode.FLOATING);
    }
  }, [displayMode, dispatch, updateDisplayMode]);
  const handleChatWidthToggle = useCallback(() => dispatch(TutorReducer.toggleChatExpansion()), [dispatch]);
  const HEADER_OPTIONS = ([{
    id: 'tutor-preferences-icon',
    label: 'Preferences',
    icon: {
      name: faEllipsis,
      rotation: 90
    },
    subOptions: [{
      id: 'tutor-restart-icon',
      label: 'Restart',
      icon: {
        name: faRedo
      },
      onClick: handleChatRestart
    }, ...(isNotificationsToggleEnabled ? [{
      id: 'tutor-toggle-notifications-icon',
      label: notificationsEnabled ? 'Turn off notifications' : 'Turn on notifications',
      icon: {
        name: notificationsEnabled ? faBellSlash : faBell
      },
      onClick: handleNotificationsToggle
    }] : []), {
      id: 'tutor-feedback-icon',
      label: 'Feedback',
      icon: {
        name: faThoughtBubble
      },
      onClick: handleSendFeedback
    }],
    onClick: () => {}
  }, ...(displayMode === TutorDisplayMode.FLOATING ? [{
    id: 'tutor-toggle-width-icon',
    label: chatWidth === 64 ? 'Shrink' : 'Expand',
    icon: {
      name: chatWidth === 64 ? faArrowsToDottedLine : faArrowsFromDottedLine,
      rotation: 90
    },
    onClick: handleChatWidthToggle
  }] : []), {
    id: 'tutor-close-icon',
    label: 'Minimize',
    icon: {
      name: displayMode === TutorDisplayMode.FLOATING ? faChevronDown : faArrowLeftToLine
    },
    onClick: handleCloseTutor
  }, {
    id: 'tutor-toggle-display',
    label: displayMode === TutorDisplayMode.FLOATING ? 'Embed in the screen' : 'Switch to floating',
    icon: {
      name: displayMode === TutorDisplayMode.EMBEDDED ? faArrowDownLeftAndArrowUpRightToCenter : faArrowUpRightAndArrowDownLeftFromCenter,
      rotation: 90
    },
    onClick: handleDisplayModeToggle
  }] as ChatWindowProps['headerOptions']);
  return <ChatWindow data-testid="chat-window" onCloseClick={handleCloseTutor} onSuggestionClick={handleSuggestionClick} onVotingClick={handleVoteMessage} onRestartClick={handleChatRestart} onSendMessageClick={handleSendMessage} onFeedbackClick={handleSendFeedback} onExpandClick={handleChatWidthToggle} messages={((messages as unknown) as ChatMessage[])} persona={persona} error={activeChatError} isLoading={isLoading && !isErrorActive} isExpanded={chatWidth === 64} isInputDisabled={isInputDisabled} landingPageUrl={DEFAULT_TUTOR_LANDING_PAGE_URL} termsOfUseBanner={{
    isVisible: !hasSeenAiTermsOfUseBanner,
    content: <>
            Echo can make mistakes. Check out our{' '}
            <a href={AI_TERMS_OF_USE_URL} target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>
          </>,
    onClick: handleTermsOfUseBannerClose
  }} introMessage={introMessage} headerOptions={HEADER_OPTIONS} onHeaderHover={handleHeaderHover} displayMode={displayMode} draggingState={draggingState} isBeta data-sentry-element="ChatWindow" data-sentry-component="TutorWindow" data-sentry-source-file="tutor-window.tsx" />;
};