import styled from 'styled-components';

import { SocialButtonCommonStyles } from 'src/common/styles';

export const StyledTitle = styled.h3`
  margin-top: 2.5rem;
  margin-bottom: 0.56rem;
  text-align: center;
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
`;

export const StyledSocialButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

export const StyledSocialButton = styled.a`
  ${SocialButtonCommonStyles};
  height: 4.5rem;
  border-radius: 3rem;
  padding: 1rem;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;
