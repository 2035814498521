import { ReactComponent as TwitterXIcon } from 'src/images/svgs/socials/x-twitter.svg';
import { ReactComponent as FacebookIcon } from 'src/images/svgs/socials/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'src/images/svgs/socials/linkedin.svg';
import { StyledTitle, StyledSocialButtonsWrapper, StyledSocialButton } from './social-media-share.styles';
import { SocialMediaShareProps } from './social-media-share.types';
export const SocialMediaShare: React.FC<SocialMediaShareProps> = ({
  pageUrl,
  shareDescription
}) => {
  const socialPlatforms = [{
    name: 'twitter-x',
    href: `https://twitter.com/intent/tweet?url=${pageUrl}&via=realtryhackme&text=${shareDescription}&hashtags=tryhackme`,
    icon: <TwitterXIcon />
  }, {
    name: 'linkedin',
    href: `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`,
    icon: <LinkedinIcon />
  }, {
    name: 'facebook',
    href: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}&t=${shareDescription}`,
    icon: <FacebookIcon />
  }];
  return <>
      <StyledTitle data-sentry-element="StyledTitle" data-sentry-source-file="social-media-share.tsx">Share on social media</StyledTitle>
      <StyledSocialButtonsWrapper data-sentry-element="StyledSocialButtonsWrapper" data-sentry-source-file="social-media-share.tsx">
        {socialPlatforms.map(platform => <StyledSocialButton key={platform.name} data-social={platform.name} target="_blank" href={platform.href}>
            {platform.icon}
          </StyledSocialButton>)}
      </StyledSocialButtonsWrapper>
    </>;
};