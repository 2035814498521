/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { io } from 'socket.io-client';
// import * as Sentry from '@sentry/react';

const URL = process.env.NODE_ENV === 'development' ? 'http://localhost:1337' : '';

export const socket = io(URL, {
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: Number.POSITIVE_INFINITY,
  timeout: 20_000,
});

export const addSocketListener = <Data, T>(event: string, callback: (data: Data) => T) => {
  socket.on(event, callback);
};

export const removeSocketListener = (event: string): void => {
  socket.off(event);
};

export const emitSocketEvent = (event: string, data: unknown): void => {
  socket.emit(event, data);
};

// Removing due a high volume of events being sent to Sentry
// TODO: Investigate the connection issues
// socket.on('connect_error', (error) => {
//   Sentry.captureException(error, {
//     extra: {
//       contexts: {
//         'socket-connect-error': { socket },
//       },
//     },
//   });
// });

socket.on('disconnect', (reason) => {
  if (reason === 'io server disconnect') {
    socket.connect();
  }
});
