import { useEffect, useState } from 'react';
import { ModalProps, theme, lightColors, Modal, ModalFooter, Button, darkColors } from '@tryhackme/thm-ui-components';
import { ThemeProvider } from 'styled-components';
import { HubspotForm } from 'src/common/hubspot-form';
import { HubspotFormProps } from 'src/common/hubspot-form/hubspot-form.types';
import { StyledModalTitle } from './hubspot-form-modal.styles';

/**
 * This component can only be used a context where the Hubspot script has been loaded.
 * Ideally, use the useExternalScript hook
 */
export const HubspotFormModal = ({
  onOpenChange,
  onFormSubmitted,
  onFormReady,
  formId,
  title = 'Book Meeting',
  colorTheme = 'light',
  ...props
}: ModalProps & {
  onFormSubmitted: HubspotFormProps['onFormSubmitted'];
  onFormReady?: HubspotFormProps['onFormReady'];
  formId: string;
  title?: string;
  colorTheme?: 'light' | 'dark';
}) => {
  const [shouldShowFooter, setShouldShowFooter] = useState(false);

  /* This is a gross hack to work around the Radix UI Dialog Modality preventing clicks from reaching the ReCaptcha
   * prompt. See the following thread on the Radix UI GitHub
   * https://github.com/radix-ui/primitives/issues/2122#issuecomment-1666753771
   */
  useEffect(() => {
    if (props.open) {
      const timer = setTimeout(() => {
        document.body.style.pointerEvents = '';
      }, 0);
      return () => clearTimeout(timer);
    }
    document.body.style.pointerEvents = 'auto';
    return () => {};
  }, [props.open]);
  return <ThemeProvider theme={{
    ...theme,
    colors: colorTheme === 'light' ? lightColors : darkColors
  }} data-sentry-element="ThemeProvider" data-sentry-component="HubspotFormModal" data-sentry-source-file="hubspot-form-modal.tsx">
      <Modal onOpenChange={state => {
      setShouldShowFooter(false);
      onOpenChange?.(state);
    }} {...props} data-sentry-element="Modal" data-sentry-source-file="hubspot-form-modal.tsx">
        <StyledModalTitle data-sentry-element="StyledModalTitle" data-sentry-source-file="hubspot-form-modal.tsx">{title}</StyledModalTitle>
        <HubspotForm
      // region="na1"
      portalId="7168674" formId={formId} onFormSubmitted={($form, data) => {
        setShouldShowFooter(true);
        onFormSubmitted?.($form, data);
      }} onFormReady={$form => onFormReady?.($form)} data-sentry-element="HubspotForm" data-sentry-source-file="hubspot-form-modal.tsx" />
        {shouldShowFooter && <ModalFooter>
            <Button variant="outlined" color="secondary" onClick={() => onOpenChange?.(false)}>
              Close
            </Button>
          </ModalFooter>}
      </Modal>
    </ThemeProvider>;
};