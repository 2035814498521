import { useState, useCallback } from 'react';
import { ChatErrorProps } from '@tryhackme/thm-ui-components';

import {
  SendTutorMessage,
  TutorErrorActionTexts,
  TutorErrorMessages,
  TutorErrorResponse,
  TutorSocketEventErrorType,
} from '../tutor.types';

interface UseTutorErrorHandlingProps {
  handleRetryableAction: (originalPayload?: SendTutorMessage) => void;
  handleSocketReconnect: () => void;
}
export const useTutorErrorHandling = ({ handleRetryableAction, handleSocketReconnect }: UseTutorErrorHandlingProps) => {
  const [activeChatError, setActiveChatError] = useState<ChatErrorProps | null>(null);

  const clearError = useCallback(() => {
    setActiveChatError(null);
  }, []);

  const handleError = useCallback(
    (error: TutorErrorResponse) => {
      if (error.type === TutorSocketEventErrorType.LIGHT_ERROR) {
        setActiveChatError({
          message: TutorErrorMessages.LIGHT_MESSAGE,
          actionText: TutorErrorActionTexts.RETRY,
          onActionClick: () => {
            handleRetryableAction();
            clearError();
          },
        });
        return;
      }

      switch (error.type) {
        case TutorSocketEventErrorType.RATE_LIMITED: {
          setActiveChatError({
            message: TutorErrorMessages.RATE_LIMITED,
            actionText: error.retryAfterMessage ?? TutorErrorActionTexts.RETRY,
            onActionClick: () => {
              handleRetryableAction(error.originalPayload);
              clearError();
            },
          });
          break;
        }

        case TutorSocketEventErrorType.CONNECTION_ERROR: {
          setActiveChatError({
            message: TutorErrorMessages.CONNECTION_ERROR,
            actionText: TutorErrorActionTexts.RECONNECT,
            onActionClick: () => {
              handleSocketReconnect();
              clearError();
            },
          });
          break;
        }

        case TutorSocketEventErrorType.INVALID_INPUT: {
          setActiveChatError({
            message: TutorErrorMessages.INVALID_INPUT,
            actionText: TutorErrorActionTexts.OK,
            onActionClick: () => {
              clearError();
            },
          });
          break;
        }

        case TutorSocketEventErrorType.SESSION_EXPIRED: {
          setActiveChatError({
            message: TutorErrorMessages.SESSION_EXPIRED,
            actionText: TutorErrorActionTexts.REFRESH_CHAT,
            onActionClick: () => {
              handleSocketReconnect();
              clearError();
            },
          });
          break;
        }

        case TutorSocketEventErrorType.MESSAGE_TOO_LONG: {
          setActiveChatError({
            message: TutorErrorMessages.MESSAGE_TOO_LONG,
            actionText: TutorErrorActionTexts.OK,
            onActionClick: () => {
              clearError();
            },
          });
          break;
        }

        default: {
          setActiveChatError({
            message: TutorErrorMessages.DEFAULT,
            actionText: TutorErrorActionTexts.REFRESH_CHAT,
            onActionClick: () => {
              handleSocketReconnect();
              clearError();
            },
          });
        }
      }
    },
    [handleRetryableAction, handleSocketReconnect, clearError],
  );

  const isErrorActive = activeChatError !== null;

  return {
    activeChatError,
    handleError,
    clearError,
    isErrorActive,
  };
};
