import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import Cookies from 'js-cookie';

const userIdFromCookie = Cookies.get('logged-in-hint');

let shouldUseProxy = false;

export const firstInitAmplitude = async () => {
  try {
    try {
      const amplitudeResponse = await fetch('https://cdn.amplitude.com/libs/analytics-browser-2.11.9-min.js.gz');
      if (!amplitudeResponse.ok) shouldUseProxy = true;
    } catch {
      shouldUseProxy = true;
    }
    if (process.env.NODE_ENV === 'production' && userIdFromCookie) {
      const sessionReplayTracking = sessionReplayPlugin();
      amplitude.add(sessionReplayTracking);
    }
    // Fetch from amplitude directly, if this errors use our insights proxy
    amplitude.init(process.env.REACT_APP_AMPLITUDE_BROWSER_KEY as string, userIdFromCookie, {
      defaultTracking: {
        attribution: false,
        pageViews: false,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
      },
      ...(shouldUseProxy && { serverUrl: 'https://insights-proxy-worker.tryhackme.com/api' }),
    });
  } catch (error) {
    if (process.env.NODE_ENV === 'development') throw error;
    // eslint-disable-next-line no-console -- we want to eat the error and not crash the app
    console.error(error);
  }
};
