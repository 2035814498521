import styled from 'styled-components';
import { darkColors } from '@tryhackme/thm-ui-components';

export const StyledScreenNavigation = styled.div<{ open: boolean }>`
  background-color: ${({ theme }) => theme.colors.secondary.main};
  padding: 0.3rem 1.6rem;
  height: 3.4rem;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.grey[200]};
`;

export const StyleActionButton = styled.button`
  position: relative;

  &:hover {
    & > div:first-of-type {
      visibility: visible;
      opacity: 1;
    }
  }

  svg {
    font-size: ${({ theme }) => theme.fonts.sizes.default};

    path {
      fill: ${({ theme }) => theme.colors.grey[100]};
    }
  }
`;

export const StyledTooltip = styled.div`
  position: absolute;
  bottom: 2.8rem;
  right: -6rem;
  visibility: hidden;
  opacity: 0;
  width: max-content;

  & > div:first-of-type {
    z-index: 2147483647;
    background-color: ${darkColors.background.tertiary};
    color: ${({ theme }) => theme.colors.grey[100]};

    &:before {
      border-top-color: ${darkColors.background.tertiary};
    }
  }

  p {
    font-size: ${({ theme }) => theme.fonts.sizes.tiny};
    width: 100%;
  }

  &[data-tooltip='full-screen'] {
    left: -2rem;
  }

  &[data-tooltip='extend-machine'] {
    right: -5.9rem;
  }

  &[data-tooltip='terminate-machine'] {
    right: -5.1rem;
  }

  &[data-tooltip='time-left'] {
    right: -1rem;
    bottom: 3rem;
  }
`;

export const StyledTimeLeft = styled.div`
  position: relative;
  &:hover {
    & > div:first-of-type {
      visibility: visible;
      opacity: 1;
    }
  }
  p {
    color: ${({ theme }) => theme.colors.grey[200]};
    width: max-content;
  }
`;

export const StyledButtonTab = styled.button`
  padding: 0.4rem 1rem;
  height: 2.8rem;

  &[aria-selected='false'] {
    background: ${({ theme }) => theme.colors.grey[900]};
  }

  &[aria-selected='true'],
  &:hover&[aria-selected='true'],
  &:hover&[aria-selected='false'] {
    background: ${({ theme }) => theme.colors.black};
  }
`;
