import { primaryColors, secondaryColors } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 2.6rem 6rem 2.6rem 4.6rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(auto, 65rem) auto;
  justify-content: space-between;
  align-items: center;
  color: ${secondaryColors.grey[200]};
  border-radius: ${({ theme }) => theme.radius.small};

  background: linear-gradient(
    257.63deg,
    #192641 3.07%,
    #293f48 59.5%,
    #243d33 87.84%,
    #5d7d38 105.57%,
    #a3ea2a 123.31%
  );

  > svg {
    height: 27.8rem;
    width: auto;
  }
`;
export const StyledTitleContainer = styled.div`
  span {
    font-size: ${({ theme }) => theme.fonts.sizes.default};
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${primaryColors.main};
  }

  h2 {
    color: ${secondaryColors.grey[200]};
    margin: 0.6rem 0 0;
    font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
    line-height: 4rem;
  }
`;

export const StyledText = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  line-height: 2.263rem;
`;
