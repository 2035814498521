import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TutorDisplayMode, TutorDraggingState, TutorMessage } from 'src/features/room/components/tutor/tutor.types';

export interface TutorState {
  stateInit: boolean;

  socketRoom: {
    joined: boolean;
    joinedAt: number;
  };

  chat: {
    open: boolean;
    sessionId: string | null;
    messages: TutorMessage[];
    loading: boolean;
    restarting: boolean;
    disabled: boolean;
    width: 40 | 64;
    height: number;
    displayMode: TutorDisplayMode;
    draggingState: TutorDraggingState;
    notificationsEnabled: boolean;
    hasSeenAiTermsOfUseBanner: boolean;
    shouldSeeIntroMessage: boolean;
  };
}

const initialState: TutorState = {
  // this is to update tutor reducer's state only one time in #useTutorSession
  // to prevent inconsistent state as we don't refetch the user data if state changes
  stateInit: false,
  socketRoom: {
    joined: false,
    joinedAt: 0,
  },
  chat: {
    open: false,
    sessionId: null,
    messages: [],
    loading: false,
    restarting: false,
    disabled: false,
    width: 40,
    height: 75.2,
    displayMode: TutorDisplayMode.EMBEDDED,
    draggingState: 'idle',
    notificationsEnabled: true,
    hasSeenAiTermsOfUseBanner: false,
    shouldSeeIntroMessage: true,
  },
};

export const tutorSlice = createSlice({
  name: 'tutor',
  initialState,
  reducers: {
    addMessages: (state, action: PayloadAction<TutorMessage[]>) => ({
      ...state,
      chat: {
        ...state.chat,
        messages: action.payload,
        loading: false,
      },
    }),

    addMessage: (state, action: PayloadAction<TutorMessage>) => ({
      ...state,
      chat: {
        ...state.chat,
        messages: [...state.chat.messages, action.payload],
        loading: true,
      },
    }),

    clearMessages: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        messages: [],
        loading: true,
        restarting: true,
        sessionId: null,
      },
    }),

    addMessageVote: (state, action: PayloadAction<{ vote: TutorMessage['vote']; messageId: string }>) => {
      const { vote, messageId } = action.payload;

      const messages = state.chat.messages.map((message) => {
        if (message.id === messageId) {
          return {
            ...message,
            vote,
          };
        }

        return message;
      });

      return {
        ...state,
        chat: {
          ...state.chat,
          messages,
        },
      };
    },

    openTutor: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        open: true,
      },
    }),

    closeTutor: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        open: false,
      },
    }),

    toggleChatExpansion: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        width: state.chat.width === 40 ? 64 : 40,
      },
    }),

    setChatHeight: (state, action: PayloadAction<number>) => ({
      ...state,
      chat: {
        ...state.chat,
        height: action.payload,
      },
    }),

    setIsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: {
        ...state.chat,
        loading: action.payload,
      },
    }),

    setIsRestarting: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: {
        ...state.chat,
        restarting: action.payload,
      },
    }),

    setNotificationsEnabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: {
        ...state.chat,
        notificationsEnabled: action.payload,
      },
    }),

    setTutorSessionId: (state, action: PayloadAction<string | null>) => ({
      ...state,
      chat: { ...state.chat, sessionId: action.payload },
    }),

    setDisplayMode: (state, action: PayloadAction<TutorDisplayMode>) => ({
      ...state,
      chat: { ...state.chat, displayMode: action.payload },
    }),

    setDraggingState: (state, action: PayloadAction<TutorDraggingState>) => ({
      ...state,
      chat: { ...state.chat, draggingState: action.payload },
    }),

    setIsChatDisabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      chat: { ...state.chat, disabled: action.payload },
    }),

    closeAiTermsOfUseBanner: (state) => ({
      ...state,
      chat: {
        ...state.chat,
        hasSeenAiTermsOfUseBanner: true,
      },
    }),

    initTutorState: (state) => ({ ...state, stateInit: true }),

    clearTutorState: () => ({
      ...initialState,
    }),

    joinSocketRoom: (state) => {
      const currentTime = Date.now();
      return {
        ...state,
        socketRoom: {
          joined: true,
          joinedAt: currentTime,
        },
      };
    },
  },
});

export const {
  openTutor,
  closeTutor,
  toggleChatExpansion,
  setChatHeight,
  setIsLoading,
  setIsRestarting,
  setNotificationsEnabled,
  setTutorSessionId,
  setDisplayMode,
  setDraggingState,
  setIsChatDisabled,
  initTutorState,
  closeAiTermsOfUseBanner,
  addMessages,
  addMessage,
  clearMessages,
  addMessageVote,
  clearTutorState,
  joinSocketRoom,
} = tutorSlice.actions;

export const tutorReducer = tutorSlice.reducer;
