import { useCallback, useEffect, useRef, useState } from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import * as TutorReducer from 'src/app/reducers/room/tutor.reducer';

import { calculateDynamicBounds } from '../tutor.helper';
import { TutorDraggingState } from '../tutor.types';

interface Position {
  x: number;
  y: number;
}

interface Bounds {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

interface UseDraggableReturn {
  position: Position;
  draggingState: TutorDraggingState;
  bounds: Bounds;
  hasDragged: React.MutableRefObject<boolean>;
  handleDragStart: (e: DraggableEvent, data: DraggableData) => void;
  handleDrag: (e: DraggableEvent, data: DraggableData) => void;
  handleDragStop: (e: DraggableEvent, data: DraggableData) => void;
  ensurePositionWithinBounds: (position: Position, bounds: Bounds) => Position;
  setDraggingState: (state: TutorDraggingState) => void;
}

const TUTOR_POSITION_KEY = 'tutor-button-position';
const DEFAULT_POSITION: Position = { x: 0, y: 0 };

export const useDraggableTutor = ({
  chatWidth,
  chatHeight,
}: {
  chatWidth: number;
  chatHeight: number;
}): UseDraggableReturn => {
  const dispatch = useAppDispatch();

  const [position, setPosition] = useState<Position>(DEFAULT_POSITION);
  const {
    chat: { draggingState },
  } = useAppSelector((state) => state.tutor);
  const [bounds, setBounds] = useState<Bounds>({ top: 0, bottom: 0, left: 0, right: 0 });
  const hasDragged = useRef(false);

  const setDraggingState = useCallback(
    (state: TutorDraggingState) => {
      dispatch(TutorReducer.setDraggingState(state));
    },
    [dispatch],
  );

  const updateBounds = useCallback(() => {
    const newBounds = calculateDynamicBounds(
      { chatWidth, chatHeight },
      { screenWidth: window.innerWidth, screenHeight: window.innerHeight },
    );
    setBounds(newBounds);
    return newBounds;
  }, [chatWidth, chatHeight]);

  const ensurePositionWithinBounds = useCallback(
    (pos: Position, currentBounds: Bounds): Position => ({
      x: Math.max(currentBounds.left, Math.min(pos.x, currentBounds.right)),
      y: Math.max(currentBounds.top, Math.min(pos.y, currentBounds.bottom)),
    }),
    [],
  );

  useEffect(() => {
    const newBounds = updateBounds();
    const savedPosition = localStorage.getItem(TUTOR_POSITION_KEY);
    if (savedPosition) {
      try {
        const parsedPosition = JSON.parse(savedPosition) as Position;
        setPosition(ensurePositionWithinBounds(parsedPosition, newBounds));
      } catch (error) {
        console.error('Failed to parse saved position:', error);
      }
    }
  }, [updateBounds, ensurePositionWithinBounds]);

  useEffect(() => {
    const handleResize = () => {
      const newBounds = updateBounds();
      setPosition((prevPosition) => ensurePositionWithinBounds(prevPosition, newBounds));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [updateBounds, ensurePositionWithinBounds]);

  const handleDragStart = useCallback(() => {
    setDraggingState('drag-start');
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollBarWidth}px`;
    document.body.style.overflow = 'hidden';
    document.body.style.userSelect = 'none';
    document.body.style.cursor = 'grabbing';
  }, [setDraggingState]);

  const handleDrag = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      const newPosition = ensurePositionWithinBounds({ x: data.x, y: data.y }, bounds);
      setPosition(newPosition);
    },
    [bounds, ensurePositionWithinBounds, setPosition],
  );

  const handleDragStop = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      setDraggingState('idle');
      hasDragged.current = false;
      document.body.style.overflow = '';
      document.body.style.userSelect = '';
      document.body.style.cursor = 'default';
      document.body.style.paddingRight = '';
      const newPosition = ensurePositionWithinBounds({ x: data.x, y: data.y }, bounds);
      setPosition(newPosition);
      localStorage.setItem(TUTOR_POSITION_KEY, JSON.stringify(newPosition));
    },
    [bounds, ensurePositionWithinBounds, setDraggingState, setPosition],
  );

  return {
    position,
    draggingState,
    bounds,
    hasDragged,
    handleDragStart,
    handleDrag,
    handleDragStop,
    ensurePositionWithinBounds,
    setDraggingState,
  };
};
