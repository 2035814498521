import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { gb, getAttributesFromCookiesAndUrl } from 'src/common/growthbook';
import { useGetUserQuery } from 'src/common/slices';

export const useGrowthbookUpdateAttributes = () => {
  const { data: userData } = useGetUserQuery();
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    gb.setAttributes(getAttributesFromCookiesAndUrl());
  }, [userData?.data?.user]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    gb.setURL(window.location.href);
  }, [location]);
};
