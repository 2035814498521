import { useState, useCallback, useEffect } from 'react';

import { useGetUserQuery } from 'src/common/slices';

import { TutorIntroMessage, TutorIntroMessageButton } from '../tutor.types';

interface UseIntroMessageProps {
  defaultConfig: TutorIntroMessage;
  roomCode: string;
  tutorSessionId: string | null | undefined;
  messagesLength: number;
  onStuckButtonClick: () => void;
  onLearnMoreClick: () => void;
}

interface UseIntroMessageReturn {
  introMessage: TutorIntroMessage;
  updateButton: (buttonKey: string, updates: Partial<TutorIntroMessageButton>) => void;
  handleButtonClick: (key: string) => void;
}

export const useIntroMessage = ({
  defaultConfig,
  roomCode,
  tutorSessionId,
  messagesLength,
  onStuckButtonClick,
  onLearnMoreClick,
}: UseIntroMessageProps): UseIntroMessageReturn => {
  const [introMessageConfig, setIntroMessageConfig] = useState<TutorIntroMessage>({
    ...defaultConfig,
    isVisible: defaultConfig.isVisible ?? true,
  });

  const { data: userData } = useGetUserQuery();

  useEffect(() => {
    let isIntroMessageVisible = false;
    if (userData?.data?.user?.settings?.tutor?.sessions) {
      const sessions = userData?.data?.user?.settings?.tutor?.sessions;
      const sessionsLength = Object.keys(sessions).length;

      const lastSession = sessions[roomCode];
      const lastSessionId = lastSession?.sessionId;
      const lastSessionIdShouldSeeIntro = lastSession?.shouldSeeIntro;

      if ((!lastSession && sessionsLength < 3) || (lastSessionId === tutorSessionId && lastSessionIdShouldSeeIntro)) {
        isIntroMessageVisible = true;
      }
    } else {
      isIntroMessageVisible = true;
    }

    setIntroMessageConfig((prev) => {
      const newConfig = {
        ...prev,
        footer: {
          ...prev.footer,
          buttons: prev.footer.buttons.map((button) =>
            button.key === 'stuck-button' ? { ...button, isVisible: messagesLength > 2 } : button,
          ),
        },
        isVisible: isIntroMessageVisible,
      };

      // Only update state if it has changed
      if (JSON.stringify(prev) !== JSON.stringify(newConfig)) {
        return newConfig;
      }
      return prev;
    });
  }, [userData, tutorSessionId, messagesLength, setIntroMessageConfig, roomCode]);

  const updateButton = useCallback((buttonKey: string, updates: Partial<TutorIntroMessageButton>) => {
    setIntroMessageConfig((prev) => ({
      ...prev,
      footer: {
        ...prev.footer,
        buttons: prev.footer.buttons.map((button) => (button.key === buttonKey ? { ...button, ...updates } : button)),
      },
    }));
  }, []);

  const handleButtonClick = useCallback(
    (key: string) => {
      const button = introMessageConfig.footer.buttons.find((btn) => btn.key === key);

      if (key === 'stuck-button') {
        if (button?.isVisible) {
          updateButton('stuck-button', { isVisible: false });
        }
        onStuckButtonClick();
      } else if (key === 'learn-more-button') {
        onLearnMoreClick();
      }

      button?.onClick?.();
      introMessageConfig.onButtonClick?.(key);
    },
    [introMessageConfig, onStuckButtonClick, onLearnMoreClick, updateButton],
  );

  return {
    introMessage: {
      ...introMessageConfig,
      onButtonClick: handleButtonClick,
    },
    updateButton,
    handleButtonClick,
  };
};
