import { useMemo } from 'react';

import { useGetCurrentB2CSubscriptionBillingDataQuery } from 'src/common/slices';

export const useAnnouncementCta = (): [string, string] => {
  const { data: b2cSubscriptionQueryData } = useGetCurrentB2CSubscriptionBillingDataQuery();

  return useMemo(() => {
    if (b2cSubscriptionQueryData?.data?.type === 'premium-monthly') {
      return ['Subscribe Now', '/manage-account/subscription '];
    }
    return ['Subscribe Now', '/why-subscribe'];
  }, [b2cSubscriptionQueryData]);
};
