import { useMemo } from 'react';
import { ItemProps } from '@tryhackme/thm-ui-components/dist/components/atoms/desktop-nav/desktop-nav.types';
import { useLocation } from 'react-router-dom';
import { useIsRoomMaker } from 'src/common/hooks/use-is-room-maker';
export const UseIsNavItemActive = (item: ItemProps) => {
  const location = useLocation();
  const hasRoomMakerRole = useIsRoomMaker();
  const activeSubItem = useMemo(() => item.subItems?.find(subItem => subItem.href === location.pathname), [item.subItems, location.pathname]);
  const isNavItemActive = useMemo(() => {
    const isUserInRoom = location.pathname.startsWith('/room/');
    const isLearnSection = item.name === 'Learn';
    const isManageRooms = location.pathname === '/rooms' && location.search.includes('tab=manage-rooms');
    const socSimPaths = ['/soc-sim/scenarios', '/soc-sim/leaderboard', '/soc-sim/stats'];
    const isSocSim = socSimPaths.includes(location.pathname);
    if (isUserInRoom && !isLearnSection) return Boolean(activeSubItem);
    if (isUserInRoom) return isLearnSection;
    if (location.pathname === '/paths' && item.name === 'Learn' || location.pathname === '/your-material/custom-development' && item.name === 'Develop' || location.pathname === '/your-material/content-engineers' && item.name === 'Develop' || location.pathname === '/develop-rooms' && item.name === 'Develop' || isManageRooms && item.name === 'Develop' || isSocSim && item.name === 'Learn') {
      return true;
    }
    if (item.name === 'Other' && hasRoomMakerRole) return false;
    return Boolean(activeSubItem);
  }, [activeSubItem, item.name, location.pathname, location.search, hasRoomMakerRole]);
  return isNavItemActive;
};