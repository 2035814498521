import { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import * as TutorReducer from 'src/app/reducers/room/tutor.reducer';

import { TutorMessage, TutorMessageSender, TutorMessageVote } from '../tutor.types';

export const useTutorMessages = () => {
  const {
    chat: { messages },
  } = useAppSelector((state) => state.tutor);

  const dispatch = useAppDispatch();

  const clearMessages = useCallback(() => dispatch(TutorReducer.clearMessages()), [dispatch]);

  const updateMessagesWithDelay = useDebouncedCallback((newMessages: TutorMessage[], callback?: () => void) => {
    dispatch(TutorReducer.addMessages(newMessages));
    if (callback) callback();
  }, 200);

  const addUserMessage = useCallback(
    (message: string, type: 'free-text' | 'button') => {
      const newMessage: TutorMessage = {
        id: `${messages.length}`,
        message,
        sender: TutorMessageSender.USER,
        type,
      };

      dispatch(TutorReducer.addMessage(newMessage));
    },
    [dispatch, messages.length],
  );

  const addUserVote = useCallback(
    (vote: TutorMessageVote, messageId: string) => {
      dispatch(TutorReducer.addMessageVote({ vote, messageId }));
    },
    [dispatch],
  );

  return {
    messages,
    updateMessagesWithDelay,
    clearMessages,
    addUserMessage,
    addUserVote,
  };
};
