import type { UIMatch } from 'react-router-dom';
import type { RouteHandleObject } from 'src/common/interfaces';

import { BANNER_BLOCKED_PAGES } from 'src/common/constants';

export const useBannerPermittedRoute = (route: UIMatch<unknown, RouteHandleObject | undefined>) => {
  const isBlockedPage = BANNER_BLOCKED_PAGES.some((page) => route.pathname.includes(page));
  const isPathOutlinePage = route.pathname.includes('/path/outline/');
  const isRoomPage = route.pathname.includes('/room/');

  return !isBlockedPage && !isPathOutlinePage && !isRoomPage;
};
