import { FC, useMemo } from 'react';
import { formatPrice } from 'src/common/helpers';
import { PriceProps } from './price.types';
export const Price: FC<PriceProps> = ({
  price,
  symbol,
  children,
  className
}) => {
  const [, int, decimal] = useMemo(() => formatPrice(price), [price]);
  return int !== null && symbol ? <span className={className}>{children(symbol + int, decimal ? `.${decimal}` : '')}</span> : null;
};