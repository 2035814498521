import {
  faArrowRight,
  faGaugeCircleBolt,
  faLightbulb,
  faQuestionCircle,
  faSparkles,
} from '@fortawesome/pro-regular-svg-icons';

import { TutorIntroMessage } from './tutor.types';

interface ChatDimensions {
  chatWidth: number;
  chatHeight: number;
}

interface ScreenDimensions {
  screenWidth: number;
  screenHeight: number;
}

interface Bounds {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

/**
 * Calculate the dynamic bounds of the draggable tutor window
 * @param chatDimensions - the dimensions of the chat window
 * @param screenDimensions - the dimensions of the screen
 * @returns the bounds of the draggable tutor window
 */

export const calculateDynamicBounds = (chatDimensions: ChatDimensions, screenDimensions: ScreenDimensions): Bounds => {
  const { chatWidth, chatHeight } = chatDimensions;
  const { screenWidth, screenHeight } = screenDimensions;

  const baseWidth = chatWidth * 10; // to px
  const baseHeight = chatHeight * 10;
  const margin = 40;

  const widthRatio = screenWidth / baseWidth;
  const heightRatio = screenHeight / baseHeight;

  const bottom = 10 * heightRatio;
  const right = screenWidth - baseWidth - widthRatio * 10 - margin;
  const top = -screenHeight + baseHeight + heightRatio * margin;

  return { top, bottom, left: 0, right };
};

/**
 * Create a tutor intro message
 * @param username - the username of the user
 * @returns a tutor intro message
 */
export const getTutorIntroMessage = ({ username }: { username: string }): TutorIntroMessage => {
  const header = `<h4>Hello, ${username}! </h4><p>I am Echo! Your AI tutor. With my help you will:</p>`;

  const body = {
    options: [
      {
        icon: faSparkles,
        text: 'Get real-time insights and suggestions from your actions on virtual machines.',
        key: 'real-time-insights-option',
      },
      {
        icon: faGaugeCircleBolt,
        text: 'Boost your learning speed and efficiency.',
        key: 'boost-learning-speed-option',
      },
      {
        icon: faLightbulb,
        text: 'Get a better understanding of room concepts and tasks.',
        key: 'better-understanding-option',
      },
    ],
  };

  const footer = {
    text: 'Stuck or want to chat? Let me know!',
    buttons: [
      {
        icon: faArrowRight,
        text: "I'm stuck",
        isPrimary: true,
        isVisible: false,
        key: 'stuck-button' as const,
        onClick: () => {},
      },
      {
        icon: faQuestionCircle,
        text: 'Learn more about me',
        isPrimary: false,
        isVisible: true,
        key: 'learn-more-button' as const,
        onClick: () => {},
      },
    ],
  };

  return {
    header,
    body,
    footer,
    isVisible: false,
  };
};
