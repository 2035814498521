export enum UserOccupation {
  STUDENT = 'student',
  CYBER_PRACTITIONER = 'cyber-practitioner',
  MANAGER = 'manager',
  DIRECTOR = 'director',
  OTHER = 'other',
  NONE = '',
}

export enum UserOccupationTeam {
  COMPLIANCE = 'compliance',
  'DATA-SCIENCE' = 'data-science',
  ENGINEERING = 'engineering',
  IT = 'IT',
  OPERATIONS = 'operations',
  QA_TESTING = 'qa-testing',
  SALES_ENGINEERING = 'sales-engineering',
  SECURITY = 'security',
  SUPPORT_HELP_DESK = 'support-help-desk',
  TECHNICAL_MARKETING = 'technical-marketing',
  OTHER = 'other',
  NONE = '',
}

export type UserTheme = 'light' | 'dark';

export enum UserInstitutionType {
  UNIVERSITY = 'university',
  COLLEGE = 'college',
  SCHOOL = 'school',
  NONE = '',
}

export const TOTAL_USERS = 4_000_000;
export const TOTAL_USERS_WITH_MILLION_UNIT = '4 million';
