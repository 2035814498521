import { useCallback, useEffect } from 'react';
import { useLocation, Outlet, Location, ScrollRestoration, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Footer, theme, darkColors, lightColors, GlobalStyles, NotificationContainer, SwitchThemeContext, AlertBanner, AlertBannerStatus } from '@tryhackme/thm-ui-components';
import { differenceInDays } from 'date-fns';
import { HeaderWrapper } from 'src/features/header';
import { ThemeColorMode } from 'src/common/enums';
import { useGetUserQuery, useUpdateCardExpiryNotificationDismissalMutation } from 'src/common/slices';
import { useAppSelector } from 'src/app/hooks';
import { useIntercom, useCustomerIOIdentify, useHotjarIdentify, useFeatureFlag, useCurrentRoute } from 'src/common/hooks';
import { FeatureFlagName } from 'src/common/constants';
import { CookieConsent } from 'src/features/cookie-consent';
import { Announcements } from 'src/features/announcements';
import { topAnnoucementBar, currentAnnouncementModal, embeddedAnnouncementBar } from 'src/features/announcements/announcements.config';
import { useShouldNotSeeNavigationExperiment } from 'src/features/room/room.helpers';
import { NewYearSaleBar } from 'src/features/announcements/components/new-year-sale-bar';
import { NewYearSaleModal } from 'src/features/announcements/components/new-year-sale-modal';
import { useGrowthbookUpdateAttributes } from 'src/common/hooks/use-growthbook-update-attributes';
import { SplitScreen } from '../split-screen/split-screen';
import { RestrictedServiceModal } from '../restricted-service-modal';
import { StyledMain } from './layout.styles';
const shouldHideFooter = (location: Location) => {
  // always show footer on the user details page, nested under /client
  if (/\/client\/\w{24}\/users\/\w{24}/i.test(location.pathname)) return false;
  const paths = ['/client/', '/workspace', '/ctf-builder'];
  const isPathMatched = paths.some(path => location.pathname.includes(path));
  return isPathMatched;
};
const colors = {
  dark: darkColors,
  light: lightColors
};
export const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const route = useCurrentRoute();
  const {
    data,
    isSuccess
  } = useGetUserQuery();
  const isRoomPage = location.pathname.includes('/room/');
  const expiringSubscriptionCardData = data?.data?.user?.cardDetails;
  const shouldNotSeeRoomNavigation = useShouldNotSeeNavigationExperiment();
  const cardExpiryDate = expiringSubscriptionCardData?.cardExpiryDate;
  const isCardExpiringSoon = expiringSubscriptionCardData?.isCardExpiringSoon;
  const {
    enabled: isCustomerCardExpiryFlagOn
  } = useFeatureFlag(FeatureFlagName.CUSTOMER_CARD_EXPIRY);
  const [updateCardExpiryDimissalDate] = useUpdateCardExpiryNotificationDismissalMutation();
  useIntercom();
  useCustomerIOIdentify();
  useHotjarIdentify();
  useGrowthbookUpdateAttributes();
  const {
    hideFooter,
    forcedTheme,
    hideHeader,
    isHeaderTransparent = false,
    isHeaderSticky = true
  } = route.handle || {};
  const managementDashboardHideFooter = shouldHideFooter(location);

  // If the route object has a set forcedTheme, apply it, otherwise use the theme as normal
  const getTheme = (themeColorMode: `${ThemeColorMode}`) => ({
    ...theme,
    colors: forcedTheme ? colors[forcedTheme] : colors[themeColorMode]
  });
  const handleDismissCardExpiryBanner = async () => {
    if (cardExpiryDate && isCardExpiringSoon) {
      const expiryDate = new Date(cardExpiryDate);
      const currentDate = new Date();
      const diffDays = differenceInDays(expiryDate, currentDate);
      await updateCardExpiryDimissalDate({
        lastCardExpiryDismissal: diffDays
      });
    }
  };
  const {
    restrictedService: isRestrictedServiceModalOpen
  } = useAppSelector(state => state.modalStates);

  // This will log the error on first load even if the SEO component is present... need to fix
  // Checker to make sure <SEO/> is used on every page
  if (process.env.NODE_ENV === 'development') {
    // stable function reference to use as event listener
    // eslint-disable-next-line react-hooks/rules-of-hooks -- this is really "conditional" at runtime
    const checkSEOComponentPresence = useCallback(() => {
      // this would only be present if <SEO/> is used
      const metaImage = document.querySelector('meta[property="og:type"]');
      if (!metaImage) {
        // eslint-disable-next-line no-console
        console.error('<meta/> OpenGraph tags are not present!\nPlease make sure you are using the <SEO/> component on the page!');
      }
    }, []);
    // register event listener for hard page loads
    // eslint-disable-next-line react-hooks/rules-of-hooks -- this is really "conditional" at runtime
    useEffect(() => {
      window.addEventListener('load', checkSEOComponentPresence);
      return () => {
        window.removeEventListener('load', checkSEOComponentPresence);
      };
    }, [checkSEOComponentPresence]);
    // also run checker on location changes (hook above at the top) and any other re-renders
    // eslint-disable-next-line react-hooks/rules-of-hooks -- this is really "conditional" at runtime
    useEffect(() => {
      // do not run before page is fully loaded (those renders can not have the final <head/>)
      if (document.readyState === 'complete') checkSEOComponentPresence();
    });
  }
  return <SwitchThemeContext.Consumer data-sentry-element="unknown" data-sentry-component="Layout" data-sentry-source-file="layout.tsx">
      {({
      theme: themeColorMode
    }) => <ThemeProvider theme={getTheme(themeColorMode)}>
          <GlobalStyles />
          <ScrollRestoration />
          <SplitScreen>
            {isCustomerCardExpiryFlagOn && cardExpiryDate && isCardExpiringSoon && <AlertBanner status={AlertBannerStatus.ERROR} message="Your payment card will expire soon. Please update your card details to avoid any disruption to your subscription" type="button" buttonText="Update Card" handleButtonOnClick={() => navigate('/manage-account/subscription?openModal=true')}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        handleBannerDismiss={handleDismissCardExpiryBanner} dismissable />}
            <Announcements config={topAnnoucementBar}>{name => <NewYearSaleBar name={name} />}</Announcements>
            <Announcements config={currentAnnouncementModal}>
              {name => <NewYearSaleModal name={name} />}
            </Announcements>
            {!hideHeader && !(shouldNotSeeRoomNavigation && isRoomPage) && <HeaderWrapper isHeaderTransparent={isHeaderTransparent} isHeaderSticky={isHeaderSticky} />}
            <StyledMain>
              <Announcements config={embeddedAnnouncementBar}>
                {name => <NewYearSaleBar thin name={name} />}
              </Announcements>
              <Outlet />
            </StyledMain>
            {!hideFooter && !managementDashboardHideFooter && <Footer isAuth={isSuccess} />}
            <NotificationContainer />
            <RestrictedServiceModal open={isRestrictedServiceModalOpen} />
          </SplitScreen>
          <CookieConsent />
        </ThemeProvider>}
    </SwitchThemeContext.Consumer>;
};