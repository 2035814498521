import { useEffect, useState } from 'react';
import { differenceInDays, parseISO } from 'date-fns';
import { Header, Modal } from '@tryhackme/thm-ui-components';
import sanitize from 'sanitize-html';
import { StreakFreezeModalContent } from 'src/common/components/streak-freeze/streak-freeze';
import { useGetUserQuery } from 'src/common/slices';
import { AccessNetworks } from 'src/features/room/components/access-networks';
import { useAppSelector } from 'src/app/hooks';
import { FeatureFlagName, LOCAL_STORAGE_REACT_NOTIFICATION_ID } from 'src/common/constants';
import { UseIsNavItemActive } from 'src/features/header/hooks/use-is-nav-item-active';
import { useCurrentRoute, useFeatureFlag } from 'src/common/hooks';
import { SignupModal } from '../signup/components/signup-modal';
import { getAdminLinks, avatarDropdownItems } from './constants';
import { useGetUserNotificationsQuery, useUpdateReadNotificationsMutation } from './notifications/notifications.slice';
import { NotificationResult, type Notification } from './notifications/notifications.types';
import { useMainNavigationLinks } from './use-main-navigation-links';
import { SearchResultsPopUp, SocSimBanner, useBannerPermittedRoute, useShowSocSimBanner } from './components';
import { HeaderWrapperProps } from './header.types';
import { StyledHeader } from './header.styles';
export const HeaderWrapper: React.FC<HeaderWrapperProps> = ({
  isHeaderTransparent = false,
  isHeaderSticky = true
}) => {
  const route = useCurrentRoute();
  const isHomepageRoute = route.pathname === '/';

  // TODO [2025-01-15] : Clean up all new practice page early access related - https://tryhackme.atlassian.net/browse/DIS-1587
  const [headerTransparent, setHeaderTransparent] = useState(isHeaderTransparent);
  const [headerSticky, setHeaderSticky] = useState(isHeaderSticky);
  const [hasStreakFreezeWonNotification, setHasStreakFreezeWonNotification] = useState(false);
  const [shownOnce, setShownOnce] = useState(false);
  const [isSlidingPaneOpen, setIsSlidingPaneOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState<boolean>(false);
  const [isSearchResultsPopUpOpen, setIsSearchResultsPopUpOpen] = useState(false);
  const {
    tutorPanelOpen,
    vmPanelOpen
  } = useAppSelector(state => state.splitScreen);
  const splitScreenOpen = tutorPanelOpen || vmPanelOpen;
  const {
    data,
    isSuccess: isUserLoggedIn
  } = useGetUserQuery();
  const {
    data: notifications
  } = useGetUserNotificationsQuery(undefined, {
    skip: !data
  });
  const [updateReadNotifications] = useUpdateReadNotificationsMutation();
  const userData = data?.data?.user;
  const expiringCardData = userData?.cardDetails;
  const cardExpiryDate = expiringCardData?.cardExpiryDate && parseISO(expiringCardData.cardExpiryDate);
  const daysUntilExpiry = cardExpiryDate && differenceInDays(cardExpiryDate, new Date());
  const isCardExpiringWithin30Days = daysUntilExpiry && daysUntilExpiry > 0 && daysUntilExpiry <= 30;
  const isCardExpired = daysUntilExpiry && daysUntilExpiry <= 0;
  const avatar = userData?.avatar ?? '';
  const streak = userData?.streak;
  const isPremium = userData?.isPremium;
  const hasCompanyId = userData?.subscriptions?.some(sub => sub?.companyId != null);
  const isPaused = userData?.isPaused && !hasCompanyId;
  const allUserNotifications = notifications?.data?.notifications.map(result => {
    const sanitizedDescription = sanitize(result.description);
    const notification = {
      ...result,
      // Comment first description and uncomment the second one using replace, to see the correct links in local environment
      description: sanitizedDescription
      // description: sanitizedDescription.replace(`href="`, `href="http://localhost:1337`),
    };

    // Only show the react improvements notification a day after the user signed up
    if (result._id === LOCAL_STORAGE_REACT_NOTIFICATION_ID && userData?.dateSignUp) {
      if (differenceInDays(new Date(), new Date(userData.dateSignUp)) < 1) return null;
      const dayAfterSignUp = new Date(userData.dateSignUp);
      dayAfterSignUp.setDate(dayAfterSignUp.getDate() + 1);
      notification.created = dayAfterSignUp.toISOString();
    }
    return notification;
  });
  const {
    specialUserNotifications,
    userNotifications
  } = categorizeNotifications(allUserNotifications);
  useEffect(() => {
    if (specialUserNotifications.length > 0 && shownOnce === false) {
      const hasWonStreakFreeze = specialUserNotifications.some(notification => notification.groupId === 'streakFreezeWon');
      if (hasWonStreakFreeze) {
        setShownOnce(true);
        setHasStreakFreezeWonNotification(hasWonStreakFreeze);
        // This localStorage is legacy support, remove when room page is moved to react
        // This won't work in localhost because its not same port number
        window.localStorage.removeItem('isAwardedStreakFreeze');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- shownOnce should only be set once
  }, [specialUserNotifications]);
  const unreadNotificationsCount = allUserNotifications?.filter(notification => notification && !notification.read).length;
  const navItems = useMainNavigationLinks();
  const handlerAccessMachine = () => {
    setIsSlidingPaneOpen(true);
  };
  const onClickSignup = () => {
    setIsSignupModalOpen(true);
  };
  const remAttackBoxBtn = useAppSelector(state => state.room.remAttackBoxBtn);
  const hasUserJoinedRoom = useAppSelector(state => state.room.hasUserJoinedRoom);
  const attackBoxPrivateIP = useAppSelector(state => state.splitScreen.attackBoxPrivateIP);
  const showAccessMachinesButton = isUserLoggedIn && hasUserJoinedRoom && !remAttackBoxBtn && !splitScreenOpen;
  const urgencyIndicator = isCardExpiringWithin30Days || isCardExpired || false;
  const {
    enabled: isSignupModalEnabled
  } = useFeatureFlag(FeatureFlagName.REACT_SIGNUP_MODAL);
  const {
    enabled: isExtendedSearchEnabled
  } = useFeatureFlag(FeatureFlagName.REACT_HEADER_SEARCH);
  const {
    enabled: isDarkModeEnabled
  } = useFeatureFlag(FeatureFlagName.REACT_DARK_MODE);
  const {
    enabled: isChristmasLogoEnabled
  } = useFeatureFlag(FeatureFlagName.CHRISTMAS_LOGO);
  const {
    enabled: isMyLearningPageEnabled
  } = useFeatureFlag(FeatureFlagName.MY_LEARNING_PAGE);
  const {
    enabled: isNewPracticePageEnabled
  } = useFeatureFlag(FeatureFlagName.REACT_NEW_PRACTICE_PAGE);
  // TODO [2025-01-15] : Clean up all new practice page early access related - https://tryhackme.atlassian.net/browse/DIS-1587
  const currentRoute = route.pathname.endsWith('/') ? route.pathname.slice(0, -1) : route.pathname;
  const isNewPracticePageRoute = ['/hacktivities', '/hacktivities/search'].includes(currentRoute);
  useEffect(() => {
    if (isNewPracticePageRoute && isNewPracticePageEnabled) setHeaderTransparent(true);else setHeaderTransparent(isHeaderTransparent);
    if (isNewPracticePageRoute && isNewPracticePageEnabled) setHeaderSticky(false);else setHeaderSticky(isHeaderSticky);
  }, [isNewPracticePageRoute, isNewPracticePageEnabled, isHeaderTransparent, isHeaderSticky]);
  const [isShowSocSimBanner, hideSocSimBanner] = useShowSocSimBanner();
  const isBannerPermittedRoute = useBannerPermittedRoute(route);
  const showSocSimBanner = isShowSocSimBanner && isBannerPermittedRoute;
  return <StyledHeader $isTransparent={headerTransparent} $showBanner={showSocSimBanner} data-sentry-element="StyledHeader" data-sentry-component="HeaderWrapper" data-sentry-source-file="header.tsx">
      {isShowSocSimBanner && isBannerPermittedRoute && <SocSimBanner dismiss={hideSocSimBanner} isUserLoggedIn={isUserLoggedIn} />}
      <Header isSearchResultsPopUpOpen={isSearchResultsPopUpOpen} setIsSearchResultsPopUpOpen={setIsSearchResultsPopUpOpen} avatar={avatar} avatarDropDownMenuItems={avatarDropdownItems(userData?.username, urgencyIndicator, isDarkModeEnabled, isMyLearningPageEnabled)} isAuth={!!data} isChristmas={isChristmasLogoEnabled} streak={streak} navItems={navItems} isPremium={isPremium} isPaused={isPaused} clearNotifications={() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateReadNotifications();
    }} accessMachineButton={{
      isVisible: showAccessMachinesButton,
      value: attackBoxPrivateIP
    }} notifications={userNotifications} unreadNotificationsCount={unreadNotificationsCount} adminLinks={getAdminLinks({
      roles: userData?.roles,
      accountType: userData?.accountType
    })} handlerAccessMachine={handlerAccessMachine} splitScreenMode={splitScreenOpen} isNavItemActive={UseIsNavItemActive} onClickSignup={isSignupModalEnabled && isHomepageRoute ? onClickSignup : undefined} isExtendedSearchEnabled={isExtendedSearchEnabled && !splitScreenOpen} searchResults={<SearchResultsPopUp setIsSearchResultsPopUpOpen={setIsSearchResultsPopUpOpen} />} searchLink="/hacktivities/search" isTransparent={headerTransparent} isSticky={headerSticky} data-sentry-element="Header" data-sentry-source-file="header.tsx" />
      <Modal preventOnOpenAutoFocus onOpenChange={open => setHasStreakFreezeWonNotification(open)} defaultOpen={false} open={hasStreakFreezeWonNotification} data-sentry-element="Modal" data-sentry-source-file="header.tsx">
        <StreakFreezeModalContent data-sentry-element="StreakFreezeModalContent" data-sentry-source-file="header.tsx" />
      </Modal>

      <AccessNetworks isSlidingPaneOpen={isSlidingPaneOpen} setIsSlidingPaneOpen={setIsSlidingPaneOpen} machineIP={attackBoxPrivateIP} data-sentry-element="AccessNetworks" data-sentry-source-file="header.tsx" />
      {isSignupModalEnabled && <SignupModal open={isSignupModalOpen} onOpenChange={setIsSignupModalOpen} />}
    </StyledHeader>;
};
function categorizeNotifications(notificationsToCategorize?: Notification[]) {
  const specialUserNotifications: NotificationResult[] = [];
  const userNotifications: NotificationResult[] = [];
  if (notificationsToCategorize) {
    for (const notification of notificationsToCategorize) {
      if (notification?.isSpecial) specialUserNotifications.push(notification);
      if (notification && !notification.isSpecial) userNotifications.push(notification);
    }
  }
  return {
    specialUserNotifications,
    userNotifications
  };
}