export enum CertificationSectionStatus {
  SECTION_INTRO = 'section_intro',
  SECTION_IN_PROGRESS = 'section_in_progress',
  SECTION_INCOMPLETE = 'section_incomplete',
  SECTION_COMPLETE = 'section_complete',
}

export enum CertificationStatus {
  INTRO_START = 'intro_start',
  INTRO_VERIFICATION = 'intro_verification',
  INTRO_AGREEMENT = 'intro_agreement',
  INTRO_VIDEO = 'intro_video',
  INTRO_CERTIFICATION_PRE_START = 'intro_certification_pre_start',
  CERTIFICATION_IN_PROGRESS = 'certification_in_progress',
  CERTIFICATION_COMPLETE = 'certification_complete',
  CERTIFICATION_INCOMPLETE = 'certification_incomplete',
  CERTIFICATION_LOADING = 'loading',
}

export enum CertificationSectionType {
  MULTIPLE_CHOICE = 'multiple_choice',
  SOC_SIM = 'soc_sim',
}

export enum CertificationType {
  SAL1 = 'SAL1',
}

export enum CertificationDuration {
  HOURS_24 = '86400000',
}

export enum CertificationExamAvailabilityStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
}
