import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { lightColors, StyledButton } from '@tryhackme/thm-ui-components';
import { AnnouncementComponentProps } from 'src/common/interfaces/announcements';
import flashSaleGraphic from 'src/images/pngs/flash-sales/flash-sale-graphic-font.png';
import { useTrackEventMutation } from 'src/common/slices';
import { Countdown } from '../countdown';
import { useAnnouncements } from '../../hooks/use-announcements';
import { useAnnouncementCta } from '../../hooks/use-announcement-cta-link';
import { NewYearSaleBarProps } from './new-year-sale-bar.types';
import { StyledBar, StyledHeading, StyledHighlight, StyledImage, StyledRow, StyledText, StyledTimerMessage, StyledWrapRow } from './new-year-sale-bar.styles';
export const NewYearSaleBar: FC<AnnouncementComponentProps & NewYearSaleBarProps> = ({
  name,
  thin
}) => {
  const theme = useTheme();
  const {
    timeLeft,
    announcement
  } = useAnnouncements(name);
  const [ctaText, urlToNavigate] = useAnnouncementCta();
  const [trackEvent] = useTrackEventMutation();
  const onCtaClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    trackEvent({
      event: 'click-subscribe-now',
      properties: {
        'clicked-on': 'top-banner'
      }
    });
  };
  return timeLeft ? <ThemeProvider theme={{
    ...theme,
    colors: lightColors
  }}>
      <StyledBar $thin={!!thin} data-testid="announcement-bar">
        <StyledRow>
          <StyledHeading $thin={!!thin}>NEW YEAR</StyledHeading>
          <StyledImage src={flashSaleGraphic} />
        </StyledRow>
        <StyledWrapRow>
          <StyledText>
            <StyledHighlight>{announcement?.amount}% off</StyledHighlight> annual subscriptions!
          </StyledText>
          {!thin && <StyledButton as={Link} to={urlToNavigate} variant="solid" color="primary" onClick={onCtaClick}>
              {ctaText}
            </StyledButton>}
        </StyledWrapRow>
        <StyledTimerMessage>Ends in:</StyledTimerMessage>
        <Countdown includeDays timeLeft={timeLeft} />
      </StyledBar>
    </ThemeProvider> : null;
};