import { useState } from 'react';

import { HookOutput } from 'src/common/components/community-section';
import { useFeatureFlag } from 'src/common/hooks';
import { FeatureFlagName } from 'src/common/constants';

import { IS_SOC_SIM_BANNER_DISMISSED_KEY } from '../soc-sim-banner.constants';

export const useShowSocSimBanner = (): HookOutput => {
  const isPreviouslyDisabled = localStorage.getItem(IS_SOC_SIM_BANNER_DISMISSED_KEY) === true.toString();
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const { enabled: isSocSimBannerEnabled } = useFeatureFlag(FeatureFlagName.SOC_SIM_BANNER);
  const isShowSocSimBanner = !isClosed && !isPreviouslyDisabled && isSocSimBannerEnabled;

  const hideSocSimBanner = () => {
    setIsClosed(true);
    localStorage.setItem(IS_SOC_SIM_BANNER_DISMISSED_KEY, true.toString());
  };

  return [isShowSocSimBanner, hideSocSimBanner] as HookOutput;
};
