import styled from 'styled-components';

import { Price } from 'src/features/pricing/components/price';

export const StyledCenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledContent = styled(StyledCenteredContent)`
  gap: 2.4rem;
`;

export const StyledHeading = styled.h2`
  font-weight: ${({ theme }) => theme.fonts.weight.bolder};
  color: ${({ theme }) => theme.colors.primary.main};
  font-size: 3.4rem;
  margin: 0;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: 5.4rem;
  }
`;

export const StyledGraphic = styled.img`
  height: 3.8rem;
  transform: translateY(-1.8rem);

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    height: 5.6rem;
    transform: translateY(-2.5rem);
  }
`;

export const StyledSubTitle = styled.h3`
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 0.6rem;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
  }
`;

export const StyledMessage = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: 2.4rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const StyledOldPrice = styled(Price)`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  text-decoration: line-through;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const StyledButtons = styled.div`
  display: flex;
  gap: 1.6rem;
`;
