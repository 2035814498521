import * as Dialog from '@radix-ui/react-dialog';
import { PanelResizeHandle } from 'react-resizable-panels';
import styled, { css } from 'styled-components';

const transitionDuration = '0.5s';

export const StyledSplitScreenWrapper = styled.div<{ open: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  overflow: ${({ open }) => (open ? 'hidden' : 'visible')};
  justify-content: center;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  position: relative;
`;

export const StyledSplitScreenLeft = styled.div<{ open: boolean }>`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 11;
`;

export const StyledSplitScreenMiddle = styled.div`
  position: relative;
  flex: 1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  > * {
    min-height: min-content;
    flex-shrink: 0;
  }
`;
export const StyledSplitScreenRight = styled.div<{ open: boolean }>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: 11;
`;

export const StyledSplitScreenContent = styled.div<{ open: boolean }>`
  ${({ open }) => (open ? `flex-grow: 1` : 'display: none')};
  flex-grow: 1;
  iframe {
    border: none;
  }
  overflow: hidden;
`;

export const StyledPanelResizeHandle = styled(PanelResizeHandle)<{ $position: 'left' | 'right'; $isDarkMode: boolean }>`
  cursor: col-resize;
  width: 0.4rem;
  height: 100%;

  background: linear-gradient(
    to bottom,
    ${({ theme }) => theme.colors.secondary.main} var(--chat-header-height),
    ${({ theme }) => theme.colors.black} var(--chat-header-height)
  );

  ${({ $position, $isDarkMode }) =>
    $position === 'left' &&
    $isDarkMode &&
    css`
      width: 0.1rem;
      background: rgba(63, 70, 91, 1);
    `}

  :hover {
    width: 0.4rem;
    background: rgba(17, 83, 228, 1);
  }
`;

export const AnimatedPanelWrapper = styled.div<{
  isOpen: boolean;
  position: 'left' | 'right';
  width: number;
  shouldAnimate: boolean;
}>`
  position: relative;
  display: flex;
  width: ${({ width }) => width}%;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: translateX(
    ${({ isOpen, position }) => {
      if (position === 'left') {
        return isOpen ? '0' : '-100%';
      }
      return isOpen ? '0' : '100%';
    }}
  );
  transition: ${({ shouldAnimate }) => (shouldAnimate ? `all ${transitionDuration} ease` : 'none')};
  overflow: hidden;
  flex-shrink: 0;
`;

export const PanelContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  display: flex;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;

export const StyledDialogContentWrapper = styled(Dialog.Content)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(184, 190, 201, 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 12;
`;

export const StyledDialogContent = styled.div`
  padding: 2.4rem;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  border-radius: ${({ theme }) => theme.radius.small};

  h5 {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    margin-bottom: 1rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.grey[200]};
  }

  p {
    max-width: 47rem;
    margin-bottom: 2.4rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.grey[200]};
  }

  button {
    &:hover:not(:disabled) {
      color: white;
    }
  }
`;
