import styled from 'styled-components';

export const StyledHeader = styled.div<{ $isTransparent: boolean; $showBanner: boolean }>`
  header {
    top: ${({ $showBanner, $isTransparent }) => ($showBanner && $isTransparent ? '49rem' : '0')};
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    header {
      top: ${({ $showBanner, $isTransparent }) => ($showBanner && $isTransparent ? '33rem' : '0')};
    }
  }
`;
