import { useCallback, useEffect } from 'react';

import { emitSocketEvent, addSocketListener, removeSocketListener, socket } from 'src/common/sockets';
import {
  TutorSocketEventType,
  SendTutorMessage,
  SendTutorMessageVote,
  ReadTutorMessages,
  TutorMessage,
} from 'src/features/room/components/tutor/tutor.types';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { joinSocketRoom } from 'src/app/reducers/room/tutor.reducer';

import { useTutorErrorHandling } from './use-tutor-error-handling';

interface UseTutorSocketProps {
  roomId?: string;
  userId?: string;
  tutorSessionId: string | null | undefined;
  setMessages: (newMessages: TutorMessage[], callback?: () => void) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsRestarting: (isRestarting: boolean) => void;
  setTutorSessionId: (sessionId: string) => void;
  setIsChatDisabled?: (isDisabled: boolean) => void;
}

export const useTutorSocket = ({
  roomId,
  tutorSessionId,
  setMessages,
  setIsLoading,
  setIsRestarting,
  setTutorSessionId,
  setIsChatDisabled,
}: UseTutorSocketProps) => {
  const { socketRoom } = useAppSelector((state) => state.tutor);
  const dispatch = useAppDispatch();

  const sendMessage = useCallback(
    (message: SendTutorMessage) => {
      if (tutorSessionId) {
        emitSocketEvent(TutorSocketEventType.SEND_MESSAGE, {
          tutorSessionId,
          roomId,
          ...message,
        });
      }
    },
    [roomId, tutorSessionId],
  );

  const readMessages = useCallback(() => {
    emitSocketEvent(TutorSocketEventType.READ_MESSAGES, {
      roomId,
      tutorSessionId: tutorSessionId ?? undefined,
    });
  }, [roomId, tutorSessionId]);

  const clearMessages = useCallback(() => {
    if (tutorSessionId) {
      emitSocketEvent(TutorSocketEventType.CLEAR_MESSAGES, { roomId, tutorSessionId });
    }
  }, [roomId, tutorSessionId]);

  const voteMessage = useCallback(
    (vote: SendTutorMessageVote) => {
      if (tutorSessionId) {
        emitSocketEvent(TutorSocketEventType.VOTE_MESSAGE, {
          ...vote,
          tutorSessionId,
        });
      }
    },
    [tutorSessionId],
  );

  const closeTermsOfUseBanner = useCallback(() => {
    emitSocketEvent(TutorSocketEventType.CLOSE_AI_TERMS_OF_USE_BANNER, {});
  }, []);

  const toggleNotifications = useCallback((state: boolean) => {
    emitSocketEvent(TutorSocketEventType.TOGGLE_NOTIFICATIONS, state);
  }, []);

  const updateDisplayMode = useCallback((mode: string) => {
    emitSocketEvent(TutorSocketEventType.UPDATE_DISPLAY_MODE, mode);
  }, []);

  useEffect(() => {
    const joinRoom = () => {
      if (socketRoom.joined && Date.now() - socketRoom.joinedAt < 1_800_000) {
        return;
      }
      emitSocketEvent('tutor-join', { roomId });
      dispatch(joinSocketRoom());
    };

    const handleReceiveMessage = (data: ReadTutorMessages) => {
      setTutorSessionId(data.tutorSessionId);
      setMessages(data.messages, () => {
        setIsLoading(false);
        setIsRestarting(false);
      });
      setIsChatDisabled?.(data.status === 'disabled');
    };

    const handleReconnect = () => {
      joinRoom();
      readMessages();
    };

    const handleVisibilityChange = () => {
      if (!document.hidden && !socket.connected) {
        socket.connect();
        handleReconnect();
      }
    };

    joinRoom();
    addSocketListener('connect', joinRoom);
    addSocketListener('reconnect', handleReconnect);
    addSocketListener(TutorSocketEventType.RECEIVE_MESSAGE, handleReceiveMessage);
    addSocketListener(TutorSocketEventType.ERROR, handleError);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      removeSocketListener(TutorSocketEventType.RECEIVE_MESSAGE);
      removeSocketListener('reconnect');
      removeSocketListener(TutorSocketEventType.ERROR);

      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { activeChatError, handleError, clearError, isErrorActive } = useTutorErrorHandling({
    handleRetryableAction: (originalPayload) => {
      if (tutorSessionId && originalPayload) {
        sendMessage(originalPayload);
        clearError();
      } else {
        readMessages();
        clearError();
      }
    },
    handleSocketReconnect: () => {
      emitSocketEvent('tutor-join', { roomId });
      dispatch(joinSocketRoom());
      readMessages();
      clearError();
    },
  });

  return {
    sendMessage,
    readMessages,
    clearMessages,
    voteMessage,
    closeTermsOfUseBanner,
    toggleNotifications,
    activeChatError,
    handleError,
    clearError,
    isErrorActive,
    updateDisplayMode,
  };
};
