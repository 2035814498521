import styled, { css } from 'styled-components';

export const StyledSocSimIconLogo = styled.img<{ isDark: boolean }>`
  position: relative;
  right: 0.3rem;
  width: 3.1rem;
  height: 3.1rem;
  object-fit: contain;
  object-position: center;
  ${({ isDark }) =>
    isDark &&
    css`
      filter: brightness(0) invert(1);
    `}

  @media screen and (max-width: ${({ theme }) => theme.sizes.md}) {
    width: 2.7rem;
    height: 2.7rem;
  }
`;
