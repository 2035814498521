import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { primaryColors, secondaryColors } from '@tryhackme/thm-ui-components';

import SocSimBannerImage from 'src/images/svgs/soc-sim/banner-header.svg';
import bg from 'src/images/pngs/soc-sim/gradient-glow.png';

export const StyledSocSimBannerContainer = styled.div`
  width: auto;
  height: fit-content;
  background: linear-gradient(
    257.42deg,
    #1c2538 3.04%,
    #213138 51.97%,
    #354c2d 81.83%,
    #5d7d38 103.87%,
    #a3ea2a 121.32%
  );
`;

export const StyledBannerImage = styled.div`
  background-image: url(${SocSimBannerImage});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => `${theme.radius.small} ${theme.radius.small} 0 0`};

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    height: 27.7rem;
  }
`;

export const StyledImageFrame = styled.div`
  border: none;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => `${theme.radius.small} ${theme.radius.small} 0 0`};

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    max-width: 60.7rem;
  }
`;

export const StyledCTAWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  margin-top: 3.3rem;
`;

export const StyledBannerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    flex-direction: row;
    gap: 0;
  }
`;

export const StyledBannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-right: 1.2rem;

  span {
    font-size: ${({ theme }) => theme.fonts.sizes.medium};
    font-family: ${({ theme }) => theme.fonts.familySansPro};
    line-height: 1.6rem;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${({ theme }) => theme.colors.primary.main};
  }

  h1 {
    font-size: 2.4rem;
    font-family: ${({ theme }) => theme.fonts.familySansPro};
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    color: ${secondaryColors.grey[100]};
    margin: 0;
  }

  p {
    font-size: ${({ theme }) => theme.fonts.sizes.medium};
    font-family: ${({ theme }) => theme.fonts.familySansPro};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: 2.26rem;
    color: ${({ theme }) => theme.colors.text.lighter};
    span {
      line-height: 2.26rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    margin: 0;
    min-width: 60rem;

    h1 {
      font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
      margin: 0;
      color: ${secondaryColors.grey[100]};
    }
  }
`;

export const StyledHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
`;

export const StyledBannerNeonLight = styled.div`
  height: 100%;

  ::before {
    content: '';
    position: absolute;
    left: 0px;
    width: 100%;
    height: 0.1rem;
    background: radial-gradient(circle, ${primaryColors.main} 10%, rgba(0, 255, 0, 0) 90%);
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    ::before {
      content: '';
      width: 70%;
      position: absolute;
      height: 0.1rem;
      background: radial-gradient(circle, ${primaryColors.main} 10%, rgba(0, 255, 0, 0) 90%);
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    width: 100%;
    display: flex;
    justify-content: center;

    ::before {
      width: 20%;
      left: 58%;
    }
  }
`;

export const StyledSocSimBannerWrapper = styled.div`
  display: flex;
  padding: 3.2em 1.8em 0;
  height: 48rem;

  button {
    span {
      font-weight: ${({ theme }) => theme.fonts.weight.bold};
    }
  }
  & > div:last-child {
    position: absolute;
    right: 2.9rem;
    svg {
      position: absolute;
      top: -20px;
      right: -14px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    padding: 3rem 3.2rem 0;
    & > div:first-child {
      flex: 2;
    }
    & > div:last-child {
      position: relative;
      display: flex;
      justify-content: end;
      flex: 1;
      svg {
        right: -40px;
        top: -10px;
      }
    }
    span,
    h1,
    p {
      text-align: left;
    }
    h1 > span {
      display: inline;
    }
    button {
      width: auto;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    padding: 4.6rem 0 0;
    max-width: 1320px;
    height: fit-content;
    margin: 0 auto;
    & > div:last-child {
      position: absolute;
      right: 2.9rem;
      svg {
        right: 0;
        top: -3px;
      }
    }
    background-image: url(${bg});
    background-size: 660px;
    background-repeat: no-repeat;
    background-position: 95% -45px;
  }
`;

export const StyledSocSimBannerCloseButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  height: 2.23rem;
  width: 2.23rem;
  color: ${({ theme }) => theme.colors.grey[500]};
  :hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
