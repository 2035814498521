import Cookies from 'js-cookie';

const generateFallbackUUID = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise, unicorn/prefer-math-trunc
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

const getAnonymousId = () => {
  const currentCookieValue = Cookies.get('thm-aid');
  // if the cookie is already set, and growthbook data is being injected (cf worker ran in front of us)
  if (currentCookieValue && document.querySelector('#__GROWTHBOOK_DATA__')) return currentCookieValue;
  // this is likely local dev env without a worker, we want to maintain the same device id or generate a new one
  const anonymousId =
    currentCookieValue || (typeof crypto === 'undefined' ? generateFallbackUUID() : crypto.randomUUID());
  if (anonymousId) Cookies.set('thm-aid', anonymousId, { expires: 400 });
  return anonymousId;
};
export const anonymousId = getAnonymousId();

export interface UserData {
  id?: string;
  experience?: string;
  isPremium?: boolean;
  dateSignUp?: string;
  email?: string;
  username?: string;
  country?: string;
}

export const getUserData = (): UserData => {
  // confirmed by line 23, as otherwise we can't convince typescript 4.9 of this
  let jsonData: Record<string, unknown>;
  try {
    jsonData = JSON.parse(decodeURIComponent(Cookies.get('thm-ud') || '') || '{}') as Record<string, unknown>;
  } catch {
    return {};
  }
  // validate the cookie is actually a json object
  if (typeof jsonData !== 'object' || !jsonData) {
    return {};
  }
  return {
    id: jsonData?.id?.toString(),
    experience: jsonData?.experience?.toString(),
    isPremium: typeof jsonData.isPremium === 'boolean' ? jsonData.isPremium : undefined,
    dateSignUp: jsonData?.dateSignUp?.toString(),
    email: jsonData?.email?.toString(),
    username: jsonData?.username?.toString(),
    country: jsonData?.country?.toString(),
  };
};

export const getRequestAttributes = () => {
  const originalUrlObject = new URL(window.location.href);
  const utms: Record<string, string> = {};
  // Add utm params from querystring
  const params = new URLSearchParams(originalUrlObject.search);
  for (const k of ['source', 'medium', 'campaign', 'term', 'content']) {
    // Querystring is in snake_case
    const param = `utm_${k}`;
    // Attribute keys are camelCase
    const attr = `utm${k[0]?.toUpperCase() || ''}${k.slice(1)}`;

    if (params.has(param)) {
      utms[attr] = params.get(param) || '';
    }
  }

  const userAgent = navigator.userAgent || '';
  return {
    /* eslint-disable no-nested-ternary */
    browser: /Edg/.test(userAgent)
      ? 'edge'
      : /Chrome/.test(userAgent)
      ? 'chrome'
      : /Firefox/.test(userAgent)
      ? 'firefox'
      : /Safari/.test(userAgent)
      ? 'safari'
      : 'unknown',
    /* eslint-enable no-nested-ternary */
    deviceType: /Mobile/.test(userAgent) ? 'mobile' : 'desktop',
    url: window.location.href,
    path: originalUrlObject.pathname,
    host: originalUrlObject.host,
    query: originalUrlObject.search,
    ...utms,
  };
};
