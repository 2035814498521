import { useEffect, useRef, useState } from 'react';

import { addSocketListener, removeSocketListener } from 'src/common/sockets';

import { TutorSocketEventType } from '../tutor.types';

interface TutorNudge {
  preview: string;
  message: string;
  status: 'active' | 'inactive' | 'idle';
}

interface UseTutorNudgeProps {
  roomId?: string;
  tutorSessionId: string | null | undefined;
  isTutorOpen: boolean;
  notificationsEnabled: boolean;
  isTutorOpenAction: () => void;
}

export const useTutorNudge = ({
  roomId,
  tutorSessionId,
  isTutorOpen,
  notificationsEnabled,
  isTutorOpenAction,
}: UseTutorNudgeProps) => {
  const [nudge, setNudge] = useState<TutorNudge>({
    status: 'idle',
    preview: '',
    message: '',
  });

  const nudgeTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isTutorOpenActionRef = useRef(isTutorOpenAction);

  useEffect(() => {
    isTutorOpenActionRef.current = isTutorOpenAction;
  }, [isTutorOpenAction]);

  const setNudgeToIdle = () => {
    setNudge({
      status: 'idle',
      preview: '',
      message: '',
    });
  };

  const clearNudge = () => {
    if (nudgeTimerRef.current) {
      clearTimeout(nudgeTimerRef.current);
      nudgeTimerRef.current = null;
    }
    setNudge({
      status: 'idle',
      preview: '',
      message: '',
    });
  };

  useEffect(() => {
    const setNudgeToInactive = () => {
      setNudge((prevState) => ({
        ...prevState,
        status: 'inactive',
      }));
      nudgeTimerRef.current = setTimeout(() => setNudgeToIdle(), 60 * 1000);
    };
    const handleReceiveNudge = (data: Omit<TutorNudge, 'status'>) => {
      if (!tutorSessionId || !notificationsEnabled) return;
      if (isTutorOpen) {
        isTutorOpenActionRef.current();
      } else {
        setNudge({
          status: 'active',
          ...data,
        });

        nudgeTimerRef.current = setTimeout(() => setNudgeToInactive(), 60 * 1000);
      }
    };
    addSocketListener(TutorSocketEventType.RECEIVE_NUDGE, handleReceiveNudge);

    return () => {
      removeSocketListener(TutorSocketEventType.RECEIVE_NUDGE);
      clearNudge();
    };
  }, [roomId, tutorSessionId, isTutorOpen, notificationsEnabled]);

  return { nudge, clearNudge };
};
