const levelToRankTitleMap = {
  1: '[0x1][NEOPHYTE]',
  2: '[0x2][APPRENTICE]',
  3: '[0x3][PATHFINDER]',
  4: '[0x4][SEEKER]',
  5: '[0x5][VISIONARY]',
  6: '[0x6][VOYAGER]',
  7: '[0x7][ADEPT]',
  8: '[0x8][HACKER]',
  9: '[0x9][MAGE]',
  10: '[0xA][WIZARD]',
  11: '[0xB][MASTER]',
  12: '[0xC][GURU]',
  13: '[0xD][LEGEND]',
  14: '[0xE][GUARDIAN]',
  15: '[0xF][TITAN]',
  16: '[0x10][SAGE]',
  17: '[0x11][VANGUARD]',
  18: '[0x12][SHOGUN]',
  19: '[0x13][ASCENDED]',
  20: '[0x14][MYTHIC]',
  21: '[0x15][ETERNAL]',
  997: '[STAFF]',
  998: '[CONTRIBUTOR]',
  999: '[BUG HUNTER]',
  1337: '[CYBER CRUSADER]',
} as const;

export const isValidRank = (level: number): level is keyof typeof levelToRankTitleMap => level in levelToRankTitleMap;

export const getRankTitle = (level: number) => (isValidRank(level) ? levelToRankTitleMap[level] : '');

export const pointWithTitles: { [key: number]: { rank: string; level: number } } = {
  0: { rank: '[0x1][NEOPHYTE]', level: 1 },
  200: { rank: '[0x2][APPRENTICE] ', level: 2 },
  500: { rank: '[0x3][PATHFINDER]', level: 3 },
  1000: { rank: '[0x4][SEEKER]', level: 4 },
  1500: { rank: '[0x5][VISIONARY]', level: 5 },
  2000: { rank: '[0x6][VOYAGER]', level: 6 },
  3000: { rank: '[0x7][ADEPT]', level: 7 },
  4000: { rank: '[0x8][HACKER]', level: 8 },
  8000: { rank: '[0x9][MAGE]', level: 9 },
  12_000: { rank: '[0xA][WIZARD]', level: 10 },
  15_000: { rank: '[0xB][MASTER]', level: 11 },
  17_000: { rank: '[0xC][GURU]', level: 12 },
  20_000: { rank: '[0xD][LEGEND]', level: 13 },
  35_000: { rank: '[0xE][GUARDIAN]', level: 14 },
  50_000: { rank: '[0xF][TITAN]', level: 15 },
  65_000: { rank: '[0x10][SAGE]', level: 16 },
  80_000: { rank: '[0x11][VANGUARD]', level: 17 },
  95_000: { rank: '[0x12][SHOGUN]', level: 18 },
  110_000: { rank: '[0x13][ASCENDED]', level: 19 },
  130_000: { rank: '[0x14][MYTHIC]', level: 20 },
  150_000: { rank: '[0x15][ETERNAL]', level: 21 },
};
